import React, { type FunctionComponent, type PropsWithChildren, type ReactElement, useEffect, useRef, useState } from 'react';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import { Link } from '../link/link.component';

export type TabProps = {
	name: string;
	isActive?: boolean;
	isDisabled?: boolean;
	url?: string;
	onClick?: Function;
	tabClasses?: string;
	tabActiveClasses?: string;
	icon?: ReactElement;
	automationHook?: string;
	renderInactiveTabs?: boolean; // Set to true to have tab content rendered for SEO purposes
};

export const Tab: FunctionComponent<PropsWithChildren<TabProps>> = ({ isActive = false, renderInactiveTabs = false, name, children }) => {
	const tabRendered = useRef(false);

	const renderTab = renderInactiveTabs || isActive || tabRendered.current;

	if (isActive && !tabRendered.current) {
		tabRendered.current = true;
	}

	return renderTab ? (
		<div role="tabpanel" data-testid={`${name.toLowerCase()}-tab-content`} className={isActive ? 'db' : 'dn'}>
			{children}
		</div>
	) : null;
};

export type TabsProps = {
	startingTabName: string;
	tabControlClasses?: string;
	tabListClasses?: string;
	children: (ReactElement | false)[];
	tabStyle?: 'DEFAULT' | 'RADIO' | 'NONE';
	onActiveTabChanged?: (tabName: string) => void;
	renderInactiveTabs?: boolean;
};

export const Tabs: FunctionComponent<TabsProps> = ({
	startingTabName,
	tabControlClasses,
	tabStyle = 'DEFAULT',
	tabListClasses = 'justify-between justify-start-ns',
	onActiveTabChanged,
	renderInactiveTabs,
	children
}) => {
	const [activeTabName, setActiveTabName] = useState(startingTabName);
	function registerClick(tabName) {
		return function handleClick() {
			setActiveTabName(tabName);
		};
	}

	// Make state respond to prop change
	useEffect(() => {
		setActiveTabName(startingTabName);
	}, [startingTabName]);

	function getTabInfo(tab: ReactElement) {
		const tabName = tab.props.name;
		const { isDisabled, url, onClick, tabClasses, tabActiveClasses, icon, automationHook } = tab.props;
		const isActive = Boolean(tabName === activeTabName);
		return {
			tabName,
			isActive,
			isDisabled,
			url,
			onClick,
			tabClasses,
			tabActiveClasses,
			icon,
			automationHook
		};
	}
	const tabList = React.Children.map(children, (child, index) => {
		// Child can be falsy when tab conditionally rendered
		if (!child) {
			return null;
		}
		const { tabName, isActive, isDisabled, url, onClick, tabClasses, tabActiveClasses, icon, automationHook } = getTabInfo(child);
		const tabStyleClasses = isDisabled
			? 'theme-grey-light disableClickEvents'
			: isActive && tabStyle === 'DEFAULT'
			? 'theme-primary'
			: 'theme-grey-darker';
		return (
			<>
				{url ? (
					<li
						className={tabClasses || 'pb3 b'}
						key={index}
						role="tab"
						onClick={onClick}
						onKeyDown={handleKeys(['Enter', ' '], onClick)}>
						<Link
							url={url}
							className={`lh-title`}
							textAlign={'tc'}
							underlineHover={true}
							color={isActive ? 'primary' : 'grey-darker'}
							automationHook={automationHook}>
							<>{icon ? icon : tabName}</>
						</Link>
					</li>
				) : (
					<li
						className={`${
							(isActive && tabActiveClasses ? tabActiveClasses : tabClasses) || 'pb3 b'
						} ${tabStyleClasses} pointer`}
						key={index}
						role="tab"
						onClick={() => {
							if (onClick) {
								onClick();
							}
							setActiveTabName(tabName);
							if (onActiveTabChanged) {
								onActiveTabChanged(tabName);
							}
						}}
						onKeyDown={handleKeys(['Enter', ' '], onClick)}
						data-automation={generateDataSelector('tab', automationHook)}>
						{tabStyle === 'RADIO' && (
							<input
								type="radio"
								aria-label={tabName}
								checked={isActive}
								className="ma0 mr2 pointer v-mid"
								onChange={registerClick(tabName)}
							/>
						)}
						{icon ? icon : tabName}
					</li>
				)}
			</>
		);
	});

	const tabPanels = React.Children.map(children, (child) => {
		// Child can be falsy when tab conditionally rendered
		if (!child) {
			return null;
		}

		const { isActive, url } = getTabInfo(child);
		return url ? null : React.cloneElement(child, { isActive, renderInactiveTabs });
	});

	return (
		<section>
			<div className={`bb ${tabControlClasses || ''}`}>
				<ul role="tablist" className={`list pl0 flex ${tabListClasses} ma0`}>
					{tabList}
				</ul>
			</div>
			{tabPanels}
		</section>
	);
};
