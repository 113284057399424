import { type ServerError } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const AKAMAI_CHALLENGE_CODE = 428;

const isServerError = (networkError): networkError is ServerError => {
	return Boolean(networkError && networkError.result && networkError.statusCode);
};

const shouldChallenge = (networkError: ServerError): boolean => networkError.statusCode === AKAMAI_CHALLENGE_CODE && window.ak_chlge;

// Apollo error link that looks for Akamai challenge responses on our
// graph requests.  If it's a challenge response, init challenge workflow from their api.
// https://techdocs.akamai.com/bot-manager/docs/challenge-actions
export const akamaiErrorLink = () =>
	onError(({ networkError }) => {
		if (isServerError(networkError) && shouldChallenge(networkError)) {
			window.ak_chlge.showChallenge(networkError.result, networkError.response.url);
		}
	});
