/**
 * This enum represents all tracked event types on site. They are intended to be
 * vendor-agnostic, and it is the responsibility of each vendor implementation
 * to map these to vendor-specific values as needed.
 * NOTE: Values must be kebab-cased
 */
export enum TrackedEvent {
	ADD_TO_CART = 'add-to-cart',
	REMOVE_FROM_CART = 'remove-from-cart',
	CHECKOUT_COMPLETE = 'checkout-complete',
	CREATE_ACCOUNT_START = 'create-account-start',
	FINDING_METHODS = 'finding-methods-tracking',
	PAGE_VIEW = 'page-view',
	PAGE_READY = 'page-ready',
	SEARCH = 'search',
	SEARCH_REDIRECT = 'search-redirect',
	COLLECTION_REDIRECT = 'collection-redirect',
	SEARCH_RESULTS_VIEW = 'view-search-results',
	CATEGORY_PLP_RESULTS_VIEW = 'view-category-plp-results',
	COLLECTION_RESULTS_VIEW = 'view-collection-results',
	NONSTOCK_SEARCH_RESULTS_VIEW = 'view-nonstock-search-results',
	PRODUCT_DETAIL_VIEW = 'product-detail-view',
	PRODUCT_CLICK = 'product-click',
	PLA_LIST_PRODUCT_CLICK = 'pla-list-card-click',
	PRODUCT_CONFIGURATION = 'product-configuration',
	FACET_INTERACTION = 'facet-interaction',
	FACET_SELECTION = 'facet-selection',
	SHOW_MORE_FACETS = 'plp-show-more-facets',
	PLA_PRODUCT_LISTS = 'pla-product-lists',
	EXPAND_FACET_GROUP = 'expand-facet-group',
	COLLAPSE_FACET_GROUP = 'collapse-facet-group',
	MORE_FACET_OPTIONS = 'more-facet-options',
	LESS_FACET_OPTIONS = 'less-facet-options',
	MORE_FILTERS = 'more-filters',
	LESS_FILTERS = 'less-filters',
	COUPON_SUBMISSION = 'coupon-submission',
	CHECKOUT = 'checkout',
	GENERIC_CLICK_TRACKING = 'generic-click-tracking',
	PROJECT_INTERACTION = 'project-interaction',
	LIVE_CHAT_INTERACTION = 'live-chat-interaction',
	VALIDATION_ERROR = 'track-validation-error',
	CUSTOMER_CHECKOUT_TYPE = 'customer-checkout-type',
	CUSTOMER_LOGIN = 'customer-login',
	PAYMENT_METHOD_CLICKED = 'track-payment-method-clicked',
	CONFIGURE_BUTTON_CLICKED = 'configure-button-clicked',
	REMOVE_BUTTON_CLICKED = 'remove-item-button-clicked',
	HIGHLIGHT_DIFFS_CLICKED = 'highlight-diffs-clicked',
	CHANGE_SELECTED_VARIANT = 'change-selected-variant',
	USER_LOGIN_FAILURE = 'user-login-failure',
	COLLECTION_LINK_CLICKED = 'collection-link',
	PRODUCT_PAGE_EVENTS = 'product-page-events',
	ECRM_ORDER_EVENT = 'ecrm-order-events',
	PRO_SIGNUP_STEP = 'pro-signup',
	CART_DRAWER_IMPRESSION = 'cart-drawer-impression',
	STICKY_NAV_CONFIGURE = 'sticky-nav-configure',
	STICKY_NAV_ACTIVATED = 'sticky-nav-active',
	STICKY_NAV_FIND_SHOWROOM = 'sticky-nav-find-showroom',
	STICKY_NAV_SELECTION_CLICKED = 'sticky-nav-selection-clicked',
	ORDER_COMPLETE = 'order-complete',
	PROMO_BANNER_CLICK = 'promo-banner-click',
	PROMO_BANNER_DISPLAY = 'promo-banner-display',
	HOMEPAGE_DASHBOARD = 'homepage-dashboard',
	ORDER_RETURN_HISTORY_SEARCH = 'order-and-return-history-search',
	HOMEPAGE_DASHBOARD_CONTROL = 'homepage-dashboard-control',
	MAIN_NAV_MENU_CLICK = 'main-nav-menu-click',
	CHANGE_SHIPPING_METHOD = 'change-shipping-method',
	PLP_SELECTED_FINISH = 'plp-selected-finish',
	PLP_SCROLLED_THROUGH_FINISHES = 'plp-scrolled-through-finishes',
	PLP_CLICKED_CONFIGURE = 'plp-clicked-configure',
	HEADER_PROJECTS_CLICK = 'header-projects-click',
	HEADER_NO_PROJECTS_CLICK = 'header-no-projects-click',
	HEADER_NOTIFICATIONS_CLICK = 'header-notifications-click',
	ECRM_NOTIFICATIONS_CLICK = 'ecrm-notifications-click',
	NOTIFICATION_CLICK = 'notification-click',
	PROJECTS_MULTI_ADD = 'projects-multi-add',
	SHOWROOM_BOOKING_FLOW = 'showroom-booking-flow',
	SHOP_BY_LOOK_PDP = 'shop-by-look-pdp',
	QUICK_STARTS_PDP = 'quick-starts-pdp',
	// This is an exception to the kebab-case rule so it will match client-side campaigns
	DY_EVENT = 'DY Event',
	APPLIANCE_PACKAGE_BUILDER_PROGRESS = 'appliance-package-builder-progress',
	PDP_MEDIA_GALLERY_REORDER = 'pdp-media-gallery-reorder',
	APPLIANCE_PACKAGE_BUILDER_INTERACTION = 'appliance-package-builder-interaction',
	REVIEWS_AND_QA_EXPANDABLES = 'reviews-and-qa-expandables',
	SUGGESTION_CALCULATOR = 'suggestion-calculator'
}

/**
 * These are user stories/use-cases which trigger a tracking event, and can be
 * thought of as sub-types of their respective TrackedEvent, above.  The purpose
 * here is to distinguish/parse events by where/why they happened.  For example,
 * the analytics team needs a standardized way to report on add-to-cart events
 * which happened on the PLA as opposed to the PDP.
 *
 * Every new page or user-interactable element which triggers an event must have
 * its own case added here!
 *
 * NOTE: Values must be kebab-cased
 */
export enum TrackedEventCase {
	// SEARCH use-cases
	KEYWORD_SEARCH_RESULTS = 'keyword-search-results',
	CATEGORY_SEARCH_RESULTS = 'category-search-results',
	COLLECTION_SEARCH_RESULTS = 'collection-search-results',
	NONSTOCK_SEARCH_RESULTS = 'nonstock-search-results',
	// SEARCH_RESULTS_VIEW use-cases
	SEARCH_RESULTS_LOAD = 'search-results-load',
	// PRODUCT_CLICK use-cases
	VARIANT_SELECT = 'finish-click',
	SEARCH_PLP_PRODUCT_CLICK = 'search-plp-product-click',
	CATEGORY_PLP_PRODUCT_CLICK = 'category-plp-product-click',
	COLLECTION_PLP_PRODUCT_CLICK = 'collection-plp-product-click',
	NONSTOCK_PLP_PRODUCT_CLICK = 'nonstock-plp-product-click',
	PRODUCT_LIST_PRODUCT_CLICK = 'product-list-product-click',
	OTHER_PRODUCT_CLICK = 'other-product-click',
	// PRODUCT_DETAIL_VIEW use-cases
	PRODUCT_DETAIL_LOAD = 'product-detail-load',
	PRODUCT_SELECT_PRICED_OPTION = 'select-priced-option',
	PRODUCT_SELECT_VARIATION = 'select-product-variation',
	PRODUCT_SELECT_CONFIGURATION_OPTION = 'select-configuration-option',
	PRODUCT_SELECT_CONFIGURATION_NESTED_OPTION = 'select-configuration-nested-option',
	PRODUCT_SELECT_CONFIGURATION_OPTION_CAROUSEL_ADVANCEMENT = 'carousel-advancement',
	PRODUCT_SELECT_CONFIGURATION_OPTION_REMOVE_ITEM = 'remove-item',
	DECLINED_PRODUCT_SELECT_CONFIGURATION_NESTED_OPTION = 'declined-select-configuration-nested-option',
	PRODUCT_VARIATION_SELECTED = 'variation-selected',
	/* This is useful for fallback behavior so analytics team can find missed or
	unhandled edge cases */
	UNKNOWN_FIX_ME = 'unknown-fix-me',
	// Official Add to Cart Methods
	ADD_TO_CART_BUY_IT_AGAIN = 'buy-it-again',
	ADD_TO_CART_CART_PAGE = 'cart-page',
	ADD_TO_CART_COMPARE_PAGE = 'product:compare',
	ADD_TO_CART_FINISH_SAMPLE = 'finish-sample',
	ADD_TO_CART_PLA_PAGE = 'pla-page',
	ADD_TO_CART_PRODUCT_PAGE = 'product-page',
	ADD_TO_CART_PRODUCT_LISTINGS_PAGE = 'plp-quick-add',
	ADD_TO_CART_RECOMMENDED_DRAWER = 'recommended-drawer',
	ADD_TO_CART_PACKAGE_ITEM = 'product:package-item',
	ADD_TO_CART_PRICED_OPTIONS = 'priced-options',
	ADD_TO_CART_PROJECTS = 'projects',
	ADD_TO_CART_STICKY_NAV = 'pdp-sticky-atc',
	REVIEWS_BUY_SECTION_LINK_CLICKED = 'reviews-buy-section-link',
	REVIEWS_INTERACTION = 'reviews-interaction',
	WRITE_REVIEW_ORDER_PAGE_SECTION = 'write-review-ecrm_order',
	QUANTITY_CHANGE = 'quantity-change',
	// HOMEPAGE_DASHBOARD use-cases
	VIEW_PROJECT = 'view-project',
	VIEW_ALL_PROJECTS = 'view-all-projects',
	VIEW_CART_ITEM = 'view-cart-item',
	VIEW_CART = 'view-cart',
	VIEW_SHOP_BY_LOOK = 'view-shop-by-look',
	VIEW_ALL_ORDERS = 'view-all-orders',
	ORDER_NUMBER_CLICK = 'order-number-click',
	WIDGETS_DISPLAYED = 'widgets-displayed',
	NO_WIDGETS_DISPLAYED = 'no-widgets-displayed',
	CATEGORY_LEVEL_ONE = 'category-level-1',
	CATEGORY_LEVEL_TWO = 'category-level-2',
	// PROJECTS
	PROJECTS_HAS_IMPORTED_BID = 'projects-has-imported-bid',
	// PROJECTS MULTI ADD
	OPEN_MODAL = 'open-modal',
	NEW_PROJECT_MULTI_SELECT = 'new-project-multi-select',
	NEW_GROUP_MULTI_SELECT = 'new-group-multi-select',
	SELECT_GROUP = 'select-group',
	COMPLETE_SAVING = 'complete-saving',
	CANCEL_CLICK = 'cancel-click',
	// NOTIFICATIONS
	OPEN_NOTIFICATION_FEED = 'open-notification-feed',
	VIEW_ALL_NOTIFICATIONS = 'view-all-notifications',
	VIEW_ORDER_NOTIFICATIONS = 'view-order-notifications',
	VIEW_PROJECT_NOTIFICATIONS = 'view-project-notifications',
	VIEW_NOTIFICATION_SETTINGS = 'view-notification-settings',
	// SHOWROOM BOOKING
	CUSTOMER_DETAILS = 'tell us about yourself',
	PROJECT_DETAILS = 'tell us about your project',
	WORK_TYPES = 'what type of project can we help with',
	SKILL_AREAS = 'select the areas youd like to discuss',
	APPOINTMENT_TYPES = 'how would you like to meet',
	TIME_SLOTS = 'select service appointment time',
	REVIEW = 'review',
	CONFIRMATION = 'confirmation',
	FINISH = 'finish',
	ATTEMPT_TO_CANCEL = 'attempt-to-cancel',
	CANCELLED_BOOKING_FLOW = 'cancelled-booking-flow',
	RETURN_TO_BOOKING_FLOW = 'are you sure you wish to leave',
	PLEASE_LOGIN_TO_CONTINUE = 'please login to continue',
	SALESFORCE_BOOKING_ID = 'salesforce-booking-id',
	// SHOP BY LOOK PDP
	VIEW_COMPLETE_LOOK = 'view-complete-look',
	FAVORITE_LOOK = 'favorite-look',
	EXPAND_CONTAINER = 'expand-container',
	VIEW_ALL_LOOKS = 'view-all-looks',
	SBL_DISPLAY_CONTROL = 'sbl-display-control',
	SBL_IS_DISPLAYED = 'sbl-is-displayed',
	// Appliance Package Builder
	PACKAGE_BUILDER_CATEGORIES = 'PKG-1-category-selection',
	PACKAGE_BUILDER_REFINERS = 'PKG-2-category-refiners',
	PACKAGE_BUILDER_RESULTS = 'PKG-3-product-selection',
	PACKAGE_BUILDER_OPTIONS = 'PKG-4-review-and-complete',
	PACKAGE_BUILDER_ADD_TO_CART = 'package-builder',
	PACKAGE_BUILDER_PROJECTS_MULTI_ADD = 'save-appliance-package',
	// PDP MEDIA GALLERY REORDER
	VIDEO_MODAL_OPENED = 'video-modal-opened',
	ADD_TO_CART_PDP_MEDIA_GALLERY_REORDER = 'add-to-cart-pdp-media-gallery-reorder',
	IMAGE_SELECTION = 'image-selection',
	MEDIA_GALLERY_MODAL_OPENED = 'media-gallery-modal-opened',
	REFINERS_TYPE = 'PKG-refiners-type',
	REFINERS_DIMENSIONS = 'PKG-refiners-dimensions',
	REFINERS_FINISH = 'PKG-refiners-finish',
	REFINERS_BRAND = 'PKG-refiners-brand',
	SELECTION_REMOVE_FILTERS = 'PKG-selection-remove-filters',
	SELECTION_NEXT = 'PKG-selection-next',
	SELECTION_PREVIOUS = 'PKG-selection-previous',
	SELECTION_DETAILS_LINK = 'PKG-selection-details-link',
	SELECTION_ADD_TO_PACKAGE = 'PKG-selection-add-to-package',
	REVIEW_ADD_TO_PACKAGE = 'PKG-review-add-to-package',
	// REVIEWS AND Q&A EXPANDABLES
	REVIEWS_EXPANDABLE_OPENED = 'reviews-expandable-opened',
	QA_EXPANDABLE_OPENED = 'qa-expandable-opened',
	REVIEWS_LOADED = 'reviews-loaded',
	QA_LOADED = 'qa-loaded',
	// CALCULATOR INTERACTION
	CALCULATOR_LOADED = 'calculator-loaded',
	CALCULATOR_USED = 'calculator-used'
}

export enum FindingMethodType {
	SEARCH = 'search',
	RECOMMENDATIONS = 'Recommendations'
}

export enum FindingMethodSubtype {
	// Search
	SEARCH_DROP = 'search-drops',
	RECENT_SEARCH_TERM = 'recent-search-term',
	SAYT_TERM = 'sayt-term-suggestion',
	SAYT_PRODUCT = 'sayt-product-suggestion',
	// Browse
	CATEGORY_DROP = 'browse-category-drop',
	// Collection
	COLLECTION_DROP = 'collection-drops',
	// Nonstock fulfillment
	NONSTOCK_DROP = 'nonstock-drops',
	// Product lists
	PRODUCT_LIST = 'product-list-drops',
	// Recommendations
	DYNAMIC_RECOMMENDATION = 'dynamic-recommendation'
}
