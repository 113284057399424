import { useMutation } from '@apollo/client/react/hooks';
import { logError } from 'fergy-core-react-logging';
import React, { type FunctionComponent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { type RequiredPick, type ServerAnalyticsInterface } from '../../../../../@types/build';
import type { AnalyticsEvent } from '../../../helpers/analytics/analytics.helper';
import { generateDataSelector } from '../../../helpers/general-helper/general-helper';
import { findLiveRoute, preloadComponentForUrl } from '../../../helpers/routing/route.helpers';
import { useTrackEvent } from '../../../hooks/analytics/analytics.hooks';
import { useNavigation } from '../../../hooks/navigation/navigation.hooks';

import { type LinkStyleProps } from './styled-link.component';

// TODO: remove LinkStyleProps once all links with visual effects are migrated to StyledLink
export type LinkProps = LinkStyleProps & {
	// Is this wrapped by StyledLink?
	isStyledLink?: boolean;
	// Analytics data that will sent upon clicking the Link.
	analyticsEvent?: AnalyticsEvent | AnalyticsEvent[];
	// An optional aria label for the link.
	ariaLabel?: string;
	// renders custom text to a data-automation attribute, used by automation engineers as a dedicated selector for e2e testing.
	automationHook?: string;
	// renders a data-analytics attribute for ad-hoc selection by the analytics team
	analyticsHook?: string;
	// Any additional CSS classes to apply.
	className?: string;
	// Used for structured data for search engine optimization
	itemProp?: string;
	// when tabbing off of this link you can provide a function to call such as opening or closing some part of the UI
	onTabOut?: () => void;
	// Determines if it should open the link in a new tab. Default is false.
	openInNewTab?: boolean;
	// GraphQL mutation to send upon clicking the Link. Intended for analytics that must be done server-side.
	serverAnalytics?: ServerAnalyticsInterface;
	/**
	 * The url to navigate to. Non-external links should be defined using relative paths.
	 * Static paths should be defined in the links.ts constants file.
	 *
	 * @see https://github.com/FergDigitalCommerce/bwf-web-store/blob/main/src/constants/links.ts
	 */
	url: string;
	tabIndex?: 0 | -1;
};

/**
 * The Link component should be used for page navigation. It will allow one to link to an internal (default)
 * or external page. It also supports notifying analytic providers about navigation. If you are looking to
 * use a custom click handler, then you should be using a variation of button instead.
 *
 * Note: if you need a link with visual effects (theme color, underline etc.) use StyledLink.
 */
export const Link = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<LinkProps>>(
	({ serverAnalytics, analyticsEvent, ...restProps }, ref) => {
		const trackEvent = useTrackEvent();
		// Create analytics onClick handler, and delegate to the appropriate component.
		const onClick = analyticsEvent
			? () =>
					new Promise<void>((resolve) => {
						if (Array.isArray(analyticsEvent)) {
							analyticsEvent.forEach((event) => {
								trackEvent(event.data);
							});
						} else {
							trackEvent(analyticsEvent.data);
						}
						// nothing asyncronous is going on, we just need to return a promise
						resolve();
					})
			: undefined;
		if (serverAnalytics?.mutation) {
			return <ServerAnalyticsLink serverAnalytics={serverAnalytics} onClick={onClick} {...restProps} ref={ref} />;
		}
		return <BaseLink onClick={onClick} {...restProps} ref={ref} />;
	}
);

/**
 * LinkProps with additional onClick handler.
 */
export type BaseLinkProps = LinkProps & {
	onClick?: () => Promise<any>;
};

/**
 * BaseLinkProps with serverAnalytics property required.
 */
export type ServerAnalyticsLinkProps = RequiredPick<BaseLinkProps, 'serverAnalytics'>;

/**
 * Create the server analytics mutation, combine it with the existing onClick handler (if any), and delegate to BaseLink.
 */
const ServerAnalyticsLink = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<ServerAnalyticsLinkProps>>(
	({ serverAnalytics, onClick, ...restProps }, ref) => {
		const { mutation, options, variables } = serverAnalytics;
		const [sendServerAnalytics] = useMutation(mutation, options);
		const clickHandler = () => Promise.all([onClick && onClick().catch(logError), sendServerAnalytics({ variables }).catch(logError)]);
		return <BaseLink {...restProps} onClick={clickHandler} ref={ref} />;
	}
);

/**
 * The "real" link component, with navigation and onClick handling.
 * Intentionally not exported to control access to the onClick prop.
 */
const BaseLink = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<BaseLinkProps>>(
	(
		{
			ariaLabel,
			automationHook,
			analyticsHook,
			children,
			className = '',
			color = 'primary',
			itemProp,
			onTabOut,
			openInNewTab = false,
			isStyledLink = false,
			underline = false,
			underlineHover = false,
			textAlign = null,
			url,
			onClick,
			tabIndex
		},
		ref
	) => {
		const internalPreload = () => preloadComponentForUrl(url);
		const target = (openInNewTab && '_blank') || undefined;
		const rel = target ? 'noreferrer' : undefined;
		const navigate = useNavigation();
		const automationId = generateDataSelector('link', automationHook);
		const analyticsId = generateDataSelector('link', analyticsHook);

		const isLiveOnReactExperience = Boolean(findLiveRoute(url));

		/**
		 * To call the`onTabOut` we need to use `onKeyDown` hook as the `press`/`keyup`
		 * event will NOT be triggered before the `blur` event.  We also want to make
		 * sure that this is only unidirectional so holding shift to reverse the tab
		 * order will NOT trigger the `onTabOut`
		 */
		const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
			if (onTabOut && e.key === 'Tab' && !e.shiftKey) {
				return onTabOut();
			}
		};
		const underLineClasses = `${underlineHover ? 'underline-hover' : ''} ${underline ? 'underline' : ''}`.trim();
		// We want StyledLink to be able to override the underline classes but not direct usages of Link
		const overrideClasses = isStyledLink ? `${underLineClasses} ${className}`.trim() : `${className} ${underLineClasses}`.trim();
		const finalClassName = `f-inherit fw-inherit link theme-${color} ${textAlign ? textAlign : ''} ${overrideClasses}`.trim();

		let clickHandler: ((e: React.MouseEvent<HTMLAnchorElement>) => void) | undefined;
		if (onClick) {
			clickHandler = (e) => {
				e.preventDefault();
				onClick()
					.catch(logError)
					.finally(() => {
						if (openInNewTab || e.metaKey || e.ctrlKey) {
							window.open(url, target, rel);
						} else {
							navigate(url);
						}
					});
			};
		}

		return isLiveOnReactExperience ? (
			<RouterLink
				to={url}
				onMouseEnter={internalPreload}
				onTouchStart={internalPreload}
				className={finalClassName}
				onClick={clickHandler}
				aria-label={ariaLabel}
				target={target}
				onKeyDown={onTabOut && onKeyDown}
				data-automation={automationId}
				data-analytics={analyticsId}
				data-testid="internal link"
				itemProp={itemProp}
				tabIndex={tabIndex}
				ref={ref}>
				{children}
			</RouterLink>
		) : (
			<a
				href={url}
				className={finalClassName}
				onClick={clickHandler}
				aria-label={ariaLabel}
				rel={rel}
				target={target}
				onKeyDown={onTabOut && onKeyDown}
				data-automation={automationId}
				data-analytics={analyticsId}
				data-testid="external link"
				itemProp={itemProp}
				ref={ref}
				tabIndex={tabIndex}>
				{children}
			</a>
		);
	}
);

export type MaybeLinkProps = {
	url?: string | null;
	children: React.ReactNode;
} & Omit<LinkProps, 'url'>;

/**
 * A link that accepts an optional url.  If no url is supplied then the links
 * children will be rendered with no containing link.
 */
export const MaybeLink: FunctionComponent<MaybeLinkProps> = ({ url, children, ...rest }) => {
	return url ? (
		<Link url={url} {...rest}>
			{children}
		</Link>
	) : (
		<>{children}</>
	);
};
