import React, { type FunctionComponent, type MouseEventHandler, useLayoutEffect, useState } from 'react';
import { OVERLAY_MARKER, type ZIndices } from '../../../constants/general';

const OVERLAY_STYLE_MAP = {
	light: 'bg-theme-white-o70',
	lightNotSmall: 'bg-theme-white-o70-ns',
	dark: 'bg-theme-black-o70',
	darkNotSmall: 'bg-theme-black-o70-ns',
	neutral: ''
};

export type OverlayStyle = keyof typeof OVERLAY_STYLE_MAP;

export type OverlayProps = {
	hasTransparentHeader?: boolean;
	onClick?: MouseEventHandler<HTMLDivElement>;
	style: OverlayStyle;
	children?: React.ReactElement;
	className?: string;
	disabled?: boolean;
	zIndex?: Exclude<ZIndices, '999' | '9999' | 'max'>;
};

export const Overlay: FunctionComponent<OverlayProps> = ({
	onClick,
	style,
	children,
	className,
	disabled = false,
	hasTransparentHeader = false,
	zIndex = 3 // TODO: Re-evaluate after an elevation diagram has been mapped out
}) => {
	const BASE_CLASSES = `fixed left-0 top-0 z-${zIndex} w-100 h-100`;
	const [headerHeight, setHeaderHeight] = useState<number>(0);
	useLayoutEffect(() => {
		const headerMarker = hasTransparentHeader
			? Array.from(document.querySelectorAll(`.${OVERLAY_MARKER}`)).find((element) => getComputedStyle(element).display !== 'none')
			: null;
		if (headerMarker) {
			setHeaderHeight(headerMarker.getBoundingClientRect().top);
		}
	}, [hasTransparentHeader]);

	// prevents click events from bubbling up and triggering unwanted actions
	function clickTrap(event) {
		event.stopPropagation();
	}

	return disabled ? (
		<>{children}</>
	) : (
		<div className={`${BASE_CLASSES}`} onClick={onClick} role="presentation" data-testid="overlay-wrapper">
			{hasTransparentHeader && <div style={{ height: headerHeight }} />}
			<div className={`${OVERLAY_STYLE_MAP[style]} ${className ?? ''} h-100 w-100`}>
				{children && React.cloneElement(children, { onClick: clickTrap })}
			</div>
		</div>
	);
};
