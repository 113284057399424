export const COOKIE_COMPARE_PRODUCTS = 'PRDUNIQUEID';
export const COOKIE_COMPARE_SHOW = 'compareshow';
export const COOKIE_LAST_PRODUCT = 'LastProduct';
export const COOKIE_LAST_SEARCH = 'LASTSEARCHPAGE';
export const COOKIE_COVID_19_HEADER_BANNER = 'covid19Banner';
export const COOKIE_SEARCH_DISABLE_PRODUCT_BOOSTS = 'search-disable-product-boosts';
export const COOKIE_SEARCH_DISABLE_REDIRECT = 'search-disable-redirect';
export const COOKIE_REACT_PROJECT_BETA = 'feat-react-project-beta';
export const COOKIE_RBS_CC_FORM_OPT_OUT = 'rbs-cc-form-opt-out';

// Expiration periods in minutes
export const TEN_YEAR_EXPIRATION_MINUTES = 10 * 365 * 24 * 60;

// Cookie expiration periods
export const TEN_YEAR_EXPIRATION = { expiresInMinutes: TEN_YEAR_EXPIRATION_MINUTES };

export const FEATURE_COOKIE_PREFIX = 'feat-';

export const COOKIE_COMPLEX_ORDER = 'complex-order';
