import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import React, { type FunctionComponent } from 'react';
import { type RouteProps } from 'react-router';
import { Route } from 'react-router-dom';
import {
	type CustomerIsAuthenticatedQuery,
	type CustomerIsAuthenticatedQueryVariables
} from '../../../client/__generated__/graphql-client-types';
import { useCustomer } from '../../hooks/apollo/customer/customer.hooks';
import { CUSTOMER_IS_AUTHENTICATED } from '../../queries/customer/customer.queries';
import { PageLoading } from '../common-components/loading/loading.component';
import { Layout, type LayoutSelectorProps } from '../layouts/layouts.component';

const LoadableChallenge = loadable(() => import(/* webpackChunkName: "authentication-challenge" */ './challenge/challenge.component'), {
	resolveComponent: ({ Challenge }) => Challenge
});

export type AuthenticatedRouteProps = LayoutSelectorProps & RouteProps & { isSoftLogin?: boolean };
export const AuthenticatedRoute: FunctionComponent<AuthenticatedRouteProps> = ({ children, layoutType, isSoftLogin = false, ...props }) => {
	const { data, loading } = useQuery<CustomerIsAuthenticatedQuery, CustomerIsAuthenticatedQueryVariables>(CUSTOMER_IS_AUTHENTICATED, {
		skip: isSoftLogin
	});
	const isAuthenticated = data?.customer?.isAuthenticated;
	const { hasAccount } = useCustomer();

	const check = isSoftLogin ? hasAccount : isAuthenticated;

	// while loading render the route, but with a loading indicator rather than children
	if (loading) {
		return (
			<Route {...props}>
				<Layout layoutType={layoutType}>
					<PageLoading />
				</Layout>
			</Route>
		);
	}
	return <Route {...props}>{check ? children : <LoadableChallenge layoutType={layoutType} />}</Route>;
};
