import React, { type FunctionComponent, useState } from 'react';
import { FEATURE_FLAGS, OVERLAY_MARKER } from '../../../constants/general';
import { HOMEPAGE_LINK, SHOWROOM_LINK, SUPPORT_FAQ_LINK } from '../../../constants/links';
import { useFeature } from '../../../hooks/features/features.hooks';
import { Link } from '../../common-components/link/link.component';
import { ProjectHeaderDropdown } from '../../project-components/project-header-dropdown/project-header-dropdown.component';
import { OmniHomeSvg } from '../../svg/general.component';
import { AccountDropdown } from '../account-dropdown/account-dropdown.component';
import { AnnouncementBanner } from '../announcement-banner/announcement-banner.component';
import { CallCenterLink } from '../call-center-link/call-center-link.component';
import { CartLink } from '../cart-link/cart-link.component';
import { ChatButton } from '../chat-button/chat-button.component';
import { DesktopNav } from '../desktop-nav/desktop-nav.component';
import { GlobalContentConstruct } from '../global-content-construct/global-content-construct.component';
import { OmniHomeMobileNav } from '../mobile-nav/omni-home/omni-home-mobile-nav.component';
import { NotificationsBadge } from '../notifications-badge/notifications-badge.component';
import { ProSignupLink } from '../pro-signup-link/pro-signup-link.component';
import { SearchBar } from '../search-bar/search-bar.component';
import { UniversalLocationIndicator } from '../universal-location-indicator/universal-location-indicator.component';
import { iconsGap, omniLogo } from './omni-home-header.css';

export const OmniHomeHeader: FunctionComponent = () => {
	const [isTypeAheadOpen, setIsTypeAheadOpen] = useState<boolean>(false);
	const isOmnihomeAnnouncementBannerActive = useFeature<boolean>(FEATURE_FLAGS.OMNI_ANNOUNCEMENT_BANNER);

	return (
		<header className="bg-theme-white dn-p" data-testid="headerComponent">
			{isOmnihomeAnnouncementBannerActive && (
				<AnnouncementBanner announcement={{ message: 'Welcome to the new FH', severity: 'LOW', id: 'omni-announcement-banner' }} />
			)}
			<div className="ph3">
				{/* Header Top Row */}
				<div className="dn flex-ns flex-row justify-between mw9 center mt2">
					{/* Location Marker */}
					<UniversalLocationIndicator />
					{/* Top Header Links */}
					<div className="flex flex-row mw9">
						<ProSignupLink />
						<nav className="flex flex-row items-center">
							<CallCenterLink linkClass="f6 omniPrimaryHover" iconClass="f5" />
							<div className="w-auto-ns tc ph2 ph0-ns mh1 mh0-ns dn db-ns">
								<ChatButton automationHook="header-chat" />
							</div>
							<Link url={SHOWROOM_LINK} className={`dn dib-ns hover-theme-primary h-100 h-auto-ns mr3-ns f6-ns`} color="grey">
								Showrooms
							</Link>
							<Link url={SUPPORT_FAQ_LINK} className={`dn dib-ns hover-theme-primary h-100 h-auto-ns f6-ns`} color="grey">
								Help
							</Link>
						</nav>
					</div>
				</div>
				{/* Header Middle Row (Main) */}
				<div className="mw9 dn db-ns pv3 center">
					<div className="flex flex-row justify-between items-center w-100">
						<Link url={HOMEPAGE_LINK} className="dib h2" ariaLabel="Navigate to home page">
							<OmniHomeSvg className={`h-100 ${omniLogo}`} />
						</Link>
						<div className="flex items-center justify-between flex-nowrap pl3 w-90 relative">
							<SearchBar onTypeaheadOpenChanged={setIsTypeAheadOpen} isBuildDomain={false} />
							<div className={`fr flex justify-end items-center pl5-l pl3-m ${iconsGap}`}>
								{!isTypeAheadOpen && (
									<>
										<ProjectHeaderDropdown />
										<AccountDropdown />
										<div className="dn db-l">
											<NotificationsBadge isBuildDomain={false} className="omniHomeNotificationBadge" />
										</div>
									</>
								)}

								<div className="f5 b flex flex-column flex-row-ns items-center justify-center">
									<CartLink isBuild={false} />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Header Nav Dropdowns */}
			<div className={`${OVERLAY_MARKER} dn db-ns`} />
			<DesktopNav />
			{/* Header Construct Content */}
			<GlobalContentConstruct />
			{/* Mobile Only */}
			<OmniHomeMobileNav />
		</header>
	);
};
