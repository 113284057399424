import React, { Component, type PropsWithChildren, createContext } from 'react';
import { type ProductImpression, type SearchProduct } from '../../types/search.types';

type ImpressionsContextState = {
	impressions: ProductImpression[];
	addImpression: (product: SearchProduct, position?: number) => void;
	removeImpression: (removedImpressions: ProductImpression[]) => void;
};

const initialState: ImpressionsContextState = {
	impressions: [],
	addImpression: () => {},
	removeImpression: () => {}
};

export const ImpressionsContext = createContext<ImpressionsContextState>(initialState);

export class ImpressionsProvider extends Component<PropsWithChildren, ImpressionsContextState> {
	readonly state: ImpressionsContextState = {
		...initialState,
		addImpression: (product, position) => {
			this.setState(({ impressions: currentImpressions }) => ({
				impressions: [...currentImpressions, { product, position }]
			}));
		},
		removeImpression: (removedImpressions) => {
			this.setState(({ impressions: currentImpressions }) => ({
				impressions: currentImpressions.filter((impression) => !removedImpressions.includes(impression))
			}));
		}
	};

	render() {
		return <ImpressionsContext.Provider value={this.state}>{this.props.children}</ImpressionsContext.Provider>;
	}
}
