import React, { type FunctionComponent, type PropsWithChildren } from 'react';
import { HelpIcon } from '../help-icon/help-icon.component';

export type LabelProps = {
	label: string;
	required?: boolean;
	className?: string;
	id?: string;
	helpTextTooltip?: React.ReactElement | string;
	// internalOnlyHelpTooltip: Pass true if the tooltip should only show for internal users and should have internal styling
	internalHelpTooltip?: boolean;
	popoverClassName?: string;
	boldLabel?: boolean;
};

export const Label: FunctionComponent<PropsWithChildren<LabelProps>> = ({
	children,
	label,
	helpTextTooltip,
	internalHelpTooltip,
	popoverClassName,
	required,
	className = '',
	id,
	boldLabel = false
}) => {
	return (
		<label className={`border-box db w-100 ${className}`} htmlFor={id}>
			<div className="flex items-center mb1">
				<div className={`${helpTextTooltip ? 'f6 mr1' : 'f6'}`}>
					<span className={boldLabel ? 'b' : undefined}>{label}</span>
					{required && <span className="theme-error ml1">*</span>}
				</div>
				{helpTextTooltip && (
					<div className="f6 mt1">
						<HelpIcon
							className={popoverClassName}
							iconClassName={`${internalHelpTooltip ? 'theme-internal' : 'theme-primary'} f6`}>
							<div>{helpTextTooltip}</div>
						</HelpIcon>
					</div>
				)}
			</div>
			{children}
		</label>
	);
};

export type MaybeLabelProps = { label?: string } & Omit<LabelProps, 'label'>;

export const MaybeLabel: FunctionComponent<PropsWithChildren<MaybeLabelProps>> = ({ label, ...remainingProps }) => {
	return label ? (
		<Label label={label} {...remainingProps}>
			{remainingProps.children}
		</Label>
	) : (
		<>{remainingProps.children}</>
	);
};
