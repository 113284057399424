import React, { type FunctionComponent } from 'react';
import { useExpandableBehavior } from '../../../hooks/expandable/expandable.hooks';
import { RemoveIcon, AddIcon } from '../../svg/icons.component';

export type ExpandableProps = {
	ariaLabel?: string;
	children: JSX.Element;
	className?: string;
	heading?: string | JSX.Element;
	startExpanded?: boolean;
	onExpand?: Function;
	onCollapse?: Function;
	renderCollapsedChildren?: boolean;
	renderIndicator?: boolean;
};

const Indicator: FunctionComponent<{ isExpanded: boolean }> = ({ isExpanded }) => {
	return (
		<div data-testid="indicator" className="f-inherit lh-solid">
			{isExpanded ? <RemoveIcon /> : <AddIcon />}
		</div>
	);
};

export const Expandable: FunctionComponent<ExpandableProps> = ({
	children,
	className = 'f-inherit pv2',
	renderCollapsedChildren = false,
	heading,
	onExpand,
	onCollapse,
	startExpanded = false,
	ariaLabel = 'expandable section',
	renderIndicator = true
}) => {
	const { isExpanded, handleKeyPress, handleClick } = useExpandableBehavior({ startExpanded, onExpand, onCollapse });

	const shouldRenderChildren = isExpanded || renderCollapsedChildren;
	return (
		<section className={`w-100 ${className}`}>
			<div
				aria-label={ariaLabel}
				className={`flex justify-${heading ? 'between' : 'end'} items-center pointer`}
				role="button"
				onClick={handleClick}
				tabIndex={0}
				onKeyPress={handleKeyPress}>
				{heading && <h1 className="ma0 lh-title f-inherit">{heading}</h1>}
				{renderIndicator && <Indicator isExpanded={isExpanded} />}
			</div>
			<div>{shouldRenderChildren && children}</div>
		</section>
	);
};
