import loadable from '@loadable/component';
import React, { type FunctionComponent, type PropsWithChildren, type ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { Route } from 'react-router-dom';
import type { VersionedRouteProps } from 'src/client/routes/routes';
import { AlertNotificationProvider } from '../../contexts/alert-notification.context';
import { AnalyticsContextProvider } from '../../contexts/analytics-context/analytics.context';
import { CompareProvider } from '../../contexts/compare/compare.context';
import { ConstructProvider } from '../../contexts/construct/construct.context';
import { ImpressionsProvider } from '../../contexts/impressions/impressions.context';
import { doesWindowExist, isChromatic } from '../../helpers/general-helper/general-helper';
import { useEmployee } from '../../hooks/apollo/employee/employee.hooks';
import { useIsBuildDomain } from '../../hooks/apollo/site/site.hooks';
import { useSetRumViewName } from '../../hooks/datadog/rum.hooks';
import { useUsabilla } from '../../hooks/usabilla/usabilla.hooks';
import { AuthenticatedRoute } from '../authenticated-route/authenticated-route.component';
import { GlobalAlertNotification } from '../common-components/alert-notification/alert-notification.component';
import { DynamicYieldPlacement } from '../dynamic-yield/placement/dynamic-yield-placement.component';
import { EmployeeOnlyRoute } from '../employee-only-route/employee-only-route.component';
import { Header } from '../header-components/header/header.component';
import { OmniHomeHeader } from '../header-components/omni-home-header/omni-home-header.component';
import { CompareDrawerButton } from '../product-components/compare-drawer-button/compare-drawer-button.component';
import { SkipLink } from '../skip-link/skip-link.component';

const MAIN_CONTENT_ID = 'main-content';

const LoadableFooter = loadable(() => import(/* webpackChunkName: "footer" */ '../footer-components/footer/footer.component'), {
	resolveComponent: ({ FooterContent }) => FooterContent
});

const Footer: FunctionComponent = () => {
	useUsabilla();

	const { ref, inView } = useInView({
		triggerOnce: true,
		rootMargin: '40px'
	});

	return (
		<footer ref={ref} className="lh-copy bg-theme-white omni-bg-theme-grey-lighter dn-p" data-testid="footerComponent">
			{(inView || isChromatic()) && <LoadableFooter />}
		</footer>
	);
};

const LoadableInternalMenu = loadable(() => import(/* webpackChunkName: "internal-menu" */ '../internal-menu/internal-menu.component'), {
	resolveComponent: ({ InternalMenu: InternalMenuComponent }) => InternalMenuComponent
});

const LoadableLiveChat = loadable(() => import(/* webpackChunkName: "live-chat" */ '../live-chat/live-chat.component'), {
	resolveComponent: ({ LiveChat: LiveChatComponent }) => LiveChatComponent
});

const LiveChat: FunctionComponent = () => {
	return doesWindowExist() ? <LoadableLiveChat /> : null;
};

const InternalMenu: FunctionComponent = () => {
	const { employee } = useEmployee();
	return employee.isAuthenticated ? <LoadableInternalMenu /> : null;
};

export type LayoutSelectorProps = {
	layoutType?: 'simple' | 'minimized';
	backgroundStyle?: 'default' | 'grey';
};

export const Layout: FunctionComponent<PropsWithChildren<LayoutSelectorProps>> = ({ layoutType, backgroundStyle, children }) => {
	if (layoutType === 'simple') {
		return <SimpleLayout>{children}</SimpleLayout>;
	} else if (layoutType === 'minimized') {
		return <MinimizedLayout>{children}</MinimizedLayout>;
	} else {
		return <DefaultLayout backgroundStyle={backgroundStyle}>{children}</DefaultLayout>;
	}
};

const RouteOrAuthRoute: FunctionComponent<PropsWithChildren<VersionedRouteProps>> = ({
	requiresAuthentication,
	requiresAccount,
	children,
	requiresEmployeeAuthentication,
	...remainingProps
}) => {
	const isSensativeRoute = requiresAccount || requiresAuthentication;
	const isSoftLogin = requiresAccount && !requiresAuthentication;

	if (isSensativeRoute) {
		return (
			<AuthenticatedRoute isSoftLogin={isSoftLogin} {...remainingProps}>
				{children}
			</AuthenticatedRoute>
		);
	} else if (requiresEmployeeAuthentication) {
		return <EmployeeOnlyRoute {...remainingProps}>{children}</EmployeeOnlyRoute>;
	} else {
		return <Route {...remainingProps}> {children}</Route>;
	}
};

export const RouteWithLayout: FunctionComponent<VersionedRouteProps> = ({ layout, component, ...remainingProps }) => {
	const { ddRum, path, backgroundStyle } = remainingProps;
	useSetRumViewName(ddRum?.rumViewNameSetByChild ? ddRum : ddRum?.rumViewName || (Array.isArray(path) ? path[0] : path) || 'Unknown');
	if (component) {
		// Type Juggling to make component types match
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const Component: FunctionComponent = component as FunctionComponent;
		return (
			<RouteOrAuthRoute {...remainingProps}>
				<AnalyticsContextProvider>
					<Layout layoutType={layout} backgroundStyle={backgroundStyle}>
						<Component />
					</Layout>
				</AnalyticsContextProvider>
			</RouteOrAuthRoute>
		);
	} else {
		return null;
	}
};

const SwitchHeader: FunctionComponent = () => {
	const isBuild = useIsBuildDomain();

	if (!isBuild) {
		return <OmniHomeHeader />;
	}

	return <Header />;
};

const FOOTER_ANCHOR_CLASSES = 'flex flex-column justify-between min-vh-100';

export type DefaultLayoutProps = { children: ReactNode; backgroundStyle?: 'default' | 'grey' };
export const DefaultLayout: FunctionComponent<DefaultLayoutProps> = ({ children, backgroundStyle = 'default' }) => (
	<CompareProvider>
		<AlertNotificationProvider>
			<ConstructProvider>
				<ImpressionsProvider>
					<div
						className={backgroundStyle === 'default' ? 'omni-layout-background-color' : 'bg-theme-grey-lighter'}
						data-testid="background-container">
						<SkipLink skipTo={MAIN_CONTENT_ID}>Skip to main content</SkipLink>
						<div id="modal-root"></div>
						<div id="drawer-root"></div>
						<GlobalAlertNotification />
						<div className={FOOTER_ANCHOR_CLASSES}>
							<div>
								<SwitchHeader />
								<main id={MAIN_CONTENT_ID}>
									<>
										{children}
										{/* the block below is increasing the bundle size by 4k because it is being used across different pages */}
										<section className="center-ns mw9-ns bg-theme-white mt3 mb3 pa0">
											<DynamicYieldPlacement dynamicYieldId="dy-recs-recently-viewed" />
										</section>
									</>
								</main>
							</div>
							<Footer />
						</div>
						<LiveChat />
						<CompareDrawerButton />
						<InternalMenu />
					</div>
				</ImpressionsProvider>
			</ConstructProvider>
		</AlertNotificationProvider>
	</CompareProvider>
);

export const SimpleLayout: FunctionComponent<{ children: ReactNode }> = ({ children }) => (
	<AlertNotificationProvider>
		<GlobalAlertNotification />
		<main>{children}</main>
		<div id="drawer-root"></div>
		<div id="modal-root"></div>
	</AlertNotificationProvider>
);

const LoadableMinimizedHeader = loadable(
	() => import(/* webpackChunkName: "minimized-header" */ '../header-components/minimized-header/minimized-header.component'),
	{
		resolveComponent: ({ MinimizedHeader }) => MinimizedHeader
	}
);

const LoadableMinimizedFooter = loadable(
	() => import(/* webpackChunkName: "minimized-footer" */ '../footer-components/minimized-footer/minimized-footer.component'),
	{
		resolveComponent: ({ MinimizedFooter }) => MinimizedFooter
	}
);

export const MinimizedLayout: FunctionComponent<{ children: ReactNode }> = ({ children }) => (
	<AlertNotificationProvider>
		<ImpressionsProvider>
			<div className="bg-theme-grey-lighter">
				<div id="modal-root"></div>
				<GlobalAlertNotification />
				<div className={FOOTER_ANCHOR_CLASSES}>
					<div>
						<LoadableMinimizedHeader />
						<main>
							<>{children}</>
						</main>
					</div>
					<LoadableMinimizedFooter />
				</div>
				<LiveChat />
				<InternalMenu />
			</div>
		</ImpressionsProvider>
	</AlertNotificationProvider>
);
