import { capitalize } from '@fergdigitalcommerce/fergy-utilities';
import { useContext } from 'react';
import { AlertNotificationContext, type AlertNotificationType } from '../../contexts/alert-notification.context';

const NOTIFICATION_TYPES: AlertNotificationType[] = ['success', 'info', 'danger', 'warning'];

/**
 * generateNotifier - given a notification function returns a reducer for creating a notifier function per notification type
 *
 * @param showNotification - notification function
 */
function generateNotifier(showNotification) {
	return (notifiers, notificationType) => {
		notifiers[`notify${capitalize(notificationType)}`] = (message: string | JSX.Element, timeout = 3000) => {
			showNotification(message, notificationType, timeout);
		};
		return notifiers;
	};
}

export interface Notifiers {
	notifySuccess: (message: string | JSX.Element, timeout?: number) => void;
	notifyInfo: (message: string | JSX.Element, timeout?: number) => void;
	notifyDanger: (message: string | JSX.Element, timeout?: number) => void;
	notifyWarning: (message: string | JSX.Element, timeout?: number) => void;
}

/**
 * `useNotification` - hook meant to simplify triggering of alert notifications
 *
 */
export function useNotification(): Notifiers {
	const { showNotification } = useContext(AlertNotificationContext);
	return NOTIFICATION_TYPES.reduce(generateNotifier(showNotification), {});
}
