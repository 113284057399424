import { type GTMPageData } from '../../helpers/analytics/gtm/gtm-types';
import { useCustomer } from '../apollo/customer/customer.hooks';
import { useEmployee } from '../apollo/employee/employee.hooks';

type UsePageDataResult = { pageData?: GTMPageData; isLoading: boolean };

export function usePageData(pageName: string): UsePageDataResult {
	const { loading: customerIsLoading, data: { customer } = {}, isPro } = useCustomer();
	const {
		loading: employeeIsLoading,
		employee: { isAuthenticated: employeeIsAuthenticated }
	} = useEmployee();

	const isLoading = customerIsLoading || employeeIsLoading;

	if (isLoading) {
		return { isLoading };
	} else {
		const pageData: GTMPageData = {
			customer: {
				isPro,
				userDBID: customer?.id ?? '0'
			},
			employee: {
				isAuthenticated: employeeIsAuthenticated
			},
			page: pageName
		};
		return { isLoading, pageData };
	}
}
