import React, { type FunctionComponent, useContext } from 'react';
import { OVERLAY_MARKER } from '../../../../constants/general';
import { HOMEPAGE_LINK } from '../../../../constants/links';
import { handleKeys } from '../../../../helpers/keyboard/keyboard.helper';
import { useRouteChange } from '../../../../hooks/routing/route-change.hook';
import { ClickableElement } from '../../../buttons';
import { Link } from '../../../common-components/link/link.component';
import { ProjectHeaderDropdown } from '../../../project-components/project-header-dropdown/project-header-dropdown.component';
import { OmniHomeSvg } from '../../../svg/general.component';
import { MenuIcon } from '../../../svg/icons.component';
import { CallCenterLink } from '../../call-center-link/call-center-link.component';
import { CartLink } from '../../cart-link/cart-link.component';
import { MobileAccountLink } from '../../mobile-account-link/mobile-account-link.component';
import { MobileNavContext, type MobileNavProps, MobileNavProvider, NavigationUI } from '../../mobile-nav/mobile-nav.component';
import { menuButton } from '../../mobile-nav/mobile-nav.css';
import { SearchBar } from '../../search-bar/search-bar.component';
import { navMenuLayout, navMenuIcon, divider, logoDimensions } from './omni-home-mobile-nav.css';

// TODO: EFDC-16856: reduce use of custom css for header
export const OmniHomeMobileNavComponent: FunctionComponent<MobileNavProps> = ({ startTabName }) => {
	const { isNavigationOpen, setIsNavigationOpen, setIsTypeaheadOpen } = useContext(MobileNavContext);
	const closeNavigation = () => setIsNavigationOpen(false);

	useRouteChange(() => {
		// make sure menu is closed if the user navigates to another page
		closeNavigation();
	});

	const showNavigation = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
		event.preventDefault();
		event.currentTarget.blur();
		setIsNavigationOpen(true);
	};

	return (
		<>
			{isNavigationOpen && <NavigationUI startTabName={startTabName} closeNavigation={closeNavigation} />}
			<div className="flex flex-column items-center dn-ns">
				{/* Logo */}
				<Link url={HOMEPAGE_LINK} className={`flex justify-center dn-ns w-100 tc pv2`} ariaLabel="Navigate to home page">
					<OmniHomeSvg className={`w-100 ${logoDimensions}`} />
				</Link>
				{/* Search Bar */}
				<div className="w-100 ph3">
					<SearchBar onTypeaheadOpenChanged={setIsTypeaheadOpen} isBuildDomain={false} placeholder="Search" />
				</div>
				{/* Nav Pane */}
				<div className={`${navMenuLayout} items-center mt2 w-100`}>
					{/* Menu */}
					<ClickableElement
						className={`${menuButton} theme-primary pointer tc active-bg-theme-grey-darker h-100 self-start omniHomeNavMenuItem`}
						onClick={showNavigation}
						onKeyDown={handleKeys([' ', 'Enter'], showNavigation)}
						ariaLabel="Site Navigation">
						<div className="flex flex-column justify-center items-center theme-primary h-100">
							<MenuIcon className={`f5 db pb1 ${navMenuIcon}`} />
							<div className={`ma0 f7`}>Menu</div>
						</div>
					</ClickableElement>
					{/* Account */}
					<MobileAccountLink />
					{/* Projects */}
					<ProjectHeaderDropdown />
					{/* Call */}
					<CallCenterLink iconClass="f5 pb1" linkClass="f7" color="primary" />
					{/* Cart */}
					<CartLink isBuild={false} isMobile={true} />
				</div>
				{/* Divider */}
				<div className={`${OVERLAY_MARKER} db dn-ns`} />
				<div className={`w-100 mv1 ${divider}`} />
			</div>
		</>
	);
};

export const OmniHomeMobileNav: FunctionComponent<MobileNavProps> = (props) => (
	<MobileNavProvider isNavigationOpen={props.startOpen || false}>
		<OmniHomeMobileNavComponent {...props} />
	</MobileNavProvider>
);
