import { VersionedRouter } from '@fergdigitalcommerce/fergy-core-react-web/esmDist/components/versioned-router';
import { createBrowserHistory } from 'history';
import React, { type FunctionComponent, useEffect } from 'react';
import { type RouteProps, type RouterProps } from 'react-router';

function hookHistoryBoomerang() {
	const BOOMR = window['BOOMR'];
	if (BOOMR && BOOMR.version && BOOMR.plugins && BOOMR.plugins.History) {
		BOOMR.plugins.History.hook(history, true);
		return true;
	}
	return false;
}

export type BuildRouterProps = {
	// your props
	onLocationChange?: (pathName: string, search: string) => void;
	exclude: RouteProps[]; // Route
} & Partial<RouterProps>;

const browserHistory = createBrowserHistory();

/**
 * The purpose of this component is to generate a router that is in charge of keeping track of the history
 * of the site. Mainly for analytical purposes.
 */
export const BuildRouter: FunctionComponent<BuildRouterProps> = ({ children, onLocationChange, history = browserHistory, exclude }) => {
	// History cannot change per react-router, so make this a one-time effect.
	useEffect(() => {
		if (!hookHistoryBoomerang()) {
			document.addEventListener('onBoomerangLoaded', hookHistoryBoomerang);
		}

		const unregisterListen = history?.listen((location) => {
			// don't force scroll to top if we're navigating to a hash
			if (!location.hash) {
				window.scrollTo(0, 0);
			}
			if (onLocationChange) {
				onLocationChange(location.pathname, location.search);
			}
		});

		return () => {
			if (unregisterListen) {
				unregisterListen();
			}
		};
	}, [history, onLocationChange]);

	return (
		<VersionedRouter history={history} exclude={exclude}>
			{children}
		</VersionedRouter>
	);
};
