import React, { type FunctionComponent } from 'react';
import { useUniversalCustomerLocation } from '../../../hooks/universal-location/universal-location.hooks';
import { StyledButton } from '../../buttons';
import { Popover } from '../../common-components/popover/popover.component';
import { TextInputWithLabel } from '../../inputs';
import { ArrowDropDownIcon } from '../../svg/icons.component';

export type LocationElementProps = {
	city?: string;
	stateAbbr?: string;
};
// TODO: EFDC-16856: reduce use of custom css for header
const DefaultLocationElement: FunctionComponent<LocationElementProps> = ({ city, stateAbbr }): JSX.Element => {
	return (
		<div className="flex items-center theme-grey omniHomeUniversalLocation pointer f6">
			<span className="mr1">Shipping to:</span>
			<span
				className="theme-primary b omniHomeUniversalLocationCity"
				data-testid={'location-element'}
				data-automation="location-dropdown">
				{!city ? 'Shipping Location' : `${city}, ${stateAbbr}`}
			</span>
			<ArrowDropDownIcon className="f4 theme-primary pt1" />
		</div>
	);
};

export const UniversalLocationIndicator: FunctionComponent = () => {
	const { isOpen, setIsOpen, location, hasError, canSubmit, errorMessage, onSubmit, register, isSubmitted } =
		useUniversalCustomerLocation('CUSTOMER_PROVIDED_UNIVERSAL');
	return (
		<Popover
			isVisible={isOpen}
			setIsVisible={setIsOpen}
			toggleElement={<DefaultLocationElement city={location?.city} stateAbbr={location?.state.abbreviation} />}
			direction="bottom">
			<form onSubmit={onSubmit} className="tc pa1 w5" aria-label="change zip code form">
				<div className="lh-copy ph4 mb f6">
					Your location helps us give you accurate shipping estimates, product availability info, and more.
				</div>
				<fieldset className="w-100 pa0 bn tl mb2">
					<TextInputWithLabel
						label="Zip Code"
						placeholder={location?.zipCode}
						invalid={hasError && isSubmitted}
						invalidMessage={errorMessage || undefined}
						data-testid="zipCodeInput"
						{...register}
						automationHook="location-zip"
					/>
				</fieldset>
				<div className={'w-100'}>
					<StyledButton testId="update-location" disabled={!canSubmit} buttonType="submit" automationHook="update-location">
						Update Location
					</StyledButton>
				</div>
			</form>
		</Popover>
	);
};
