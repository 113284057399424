import React, { type FunctionComponent } from 'react';
import { type LinkStyleProps } from '../../common-components/link/styled-link.component';
import { BaseButton, type BaseButtonProps } from '../base-button/base-button.component';

export type TextButtonProps = LinkStyleProps & Omit<BaseButtonProps, 'size'>;

export const TextButton: FunctionComponent<TextButtonProps> = ({
	color = 'primary',
	textAlign = 'tc',
	underlineHover,
	underline,
	className = '',
	...remainingProps
}) => {
	let omniClassNames = `text-button-${color}`;
	if (((color === 'primary' || color === 'internal') && underline !== false) || underline === true) {
		omniClassNames += ' text-button-underline';
	}

	return (
		<BaseButton
			size={null}
			className={
				// requires pattern in postcss.config.js
				`f-inherit fw-inherit bw0 pa0 link theme-${color} ${underlineHover ? 'underline-hover' : ''} ${
					underline ? 'underline' : ''
				} bg-transparent ${textAlign} ${className} ${omniClassNames}`
			}
			{...remainingProps}
		/>
	);
};
