import React, { type FunctionComponent } from 'react';
import { JsonLd } from 'react-schemaorg';
import { type Product } from 'schema-dts';
import { SITE_NAME } from '../../../../constants/general';
import { STRUCTURED_DATA_IMAGE_OPTIONS } from '../../../../constants/images';
import { SITE_PATH } from '../../../../constants/links';
import { generateCloudinaryUrl } from '../../../../helpers/cloudinary-helper/cloudinary-helper';
import { isProductPage, type PageTypes } from '../page-container/page-container.types';

export type PageStructuredDataProps = {
	pageTypeProps: PageTypes;
};

export const PageStructuredData: FunctionComponent<PageStructuredDataProps> = ({ pageTypeProps }) => {
	if (isProductPage(pageTypeProps)) {
		return (
			<JsonLd<Product>
				item={{
					'@context': 'https://schema.org',
					'@type': 'Product',
					name: pageTypeProps.productName,
					description: pageTypeProps.productDescription || undefined,
					productID: pageTypeProps.productId,
					image: generateCloudinaryUrl(pageTypeProps.imagePublicId, STRUCTURED_DATA_IMAGE_OPTIONS),
					sku: pageTypeProps.modelNumber,
					brand: {
						'@type': 'Brand',
						name: pageTypeProps.brandName
					},
					offers: {
						'@type': 'Offer',
						'@id': SITE_PATH + pageTypeProps.productUrl,
						priceCurrency: 'USD',
						price: pageTypeProps.price,
						sku: pageTypeProps.modelNumber,
						availability: pageTypeProps.itemAvailability,
						seller: {
							'@type': 'Organization',
							name: SITE_NAME
						},
						url: SITE_PATH + pageTypeProps.productUrl
					},
					...(pageTypeProps.ratingCount &&
						pageTypeProps.ratingValue && {
							aggregateRating: {
								'@type': 'AggregateRating',
								ratingValue: pageTypeProps.ratingValue,
								reviewCount: pageTypeProps.ratingCount,
								worstRating: 1,
								bestRating: 5
							}
						})
				}}
			/>
		);
	}
	return null;
};
