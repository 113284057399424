import { type ImageFieldsFragment } from '../../__generated__/graphql-client-types';
import { type CloudinaryImageProps } from '../../components/images/cloudinary-image/cloudinary-image.component';
import { NO_IMAGE_URL } from '../../constants/images';
import { isChromatic } from '../general-helper/general-helper';
const baseURL = 'https://s3.img-b.com';

type OverlayOption = {
	publicId: string;
	options?: CloudinaryOptions;
};

/**
 * @see https://cloudinary.com/documentation/image_transformation_reference
 */
export type CloudinaryOptions = {
	crop?:
		| 'scale'
		| 'fit'
		| 'mfit'
		| 'fill'
		| 'lfill'
		| 'fill_pad'
		| 'limit'
		| 'pad'
		| 'lpad'
		| 'mpad'
		| 'crop'
		| 'thumb'
		| 'imagga_crop'
		| 'imagga_scale';
	gravity?:
		| 'auto'
		| 'north_east'
		| 'north'
		| 'north_west'
		| 'west'
		| 'south_west'
		| 'south'
		| 'south_east'
		| 'east'
		| 'center'
		| 'face'
		| 'custom';
	width?: number;
	height?: number;
	radius?: number | string;
	type?: CloudinaryImageType;
	dpr?: 'auto' | number | null;
	preserveTransparency?: boolean;
	zoom?: string;
	overlay?: OverlayOption;
	format?: 'auto' | 'png' | 'jpg' | 'gif' | 'svg';
	quality?: number;
	effect?: 'blur' | 'pixelate';
	effectAmount?: number;
	fallbackImage?: string;
};

export type CloudinaryImageType = 'upload' | 'private' | 'fetch';

function generateOverlayTransformations(overlay: OverlayOption) {
	const overlayParts = [`/l_${overlay.publicId.replace(/\//g, ':')}`];
	if (overlay.options) {
		overlayParts.push(generateTransformations(overlay.options));
	}
	return overlayParts.join();
}

/**
 * Cloudinary options are controlled by key terms in the request url.  This
 * function generates the string representing the transformation options
 *
 * @param options
 */
function generateTransformations(options: CloudinaryOptions): string {
	const {
		width,
		height,
		gravity,
		radius,
		crop = 'lpad',
		dpr = 'auto',
		preserveTransparency,
		zoom,
		overlay,
		format = 'auto',
		quality,
		effect,
		effectAmount,
		fallbackImage
	} = options || {};
	const transformations: string[] = ['t_base', `c_${crop}`, `f_${format}`];
	if (dpr) {
		transformations.push(`dpr_${dpr}`);
	}
	if (gravity) {
		transformations.push(`g_${gravity}`);
	}
	if (width) {
		transformations.push(`w_${width}`);
	}
	if (height) {
		transformations.push(`h_${height}`);
	}
	if (radius) {
		transformations.push(`r_${radius}`);
	}
	if (preserveTransparency) {
		transformations.push('fl_preserve_transparency');
	}
	if (zoom) {
		transformations.push(`z_${zoom}`);
	}
	if (quality) {
		transformations.push(`q_${quality}`);
	}
	if (effect) {
		transformations.push(`e_${effect}:${effectAmount || 0}`);
	}
	if (fallbackImage) {
		transformations.push(`d_${fallbackImage}`);
	}
	if (overlay) {
		return transformations.join() + generateOverlayTransformations(overlay);
	} else {
		return transformations.join();
	}
}

/**
 * This function generates the request url for the image based on the
 * Cloudinary publicId and options meta data
 */
export function generateCloudinaryUrl(publicID: string, options: CloudinaryOptions, isSrcSet = false): string {
	if (publicID.endsWith('.svg') && !options.format) {
		options.format = 'svg';
	}
	const transformations = generateTransformations(options);
	const imageType = options.type || 'private';
	let imageURL = `${baseURL}/image/${imageType}/${transformations}/${publicID}`;

	if (isSrcSet && isChromatic()) {
		const url = new URL(imageURL);
		url.searchParams.set('cacheBust', Date.now().toString());
		imageURL = url.toString();
	}

	return imageURL;
}

/**
 * This function takes a graphQL Image and options and returns cloudinary image props that can be spread into a CloudinaryImage component
 *
 * @param {Image} image
 * @param {CloudinaryOptions} [imageOptions]
 * @param {CloudinaryImageProps} [imageProps]
 * @returns {CloudinaryImageProps}
 */
export function generateCloudinaryImageProps(
	image: ImageFieldsFragment,
	imageOptions: CloudinaryOptions = {},
	imageProps: Partial<CloudinaryImageProps> = {}
): CloudinaryImageProps {
	return {
		...imageProps,
		publicID: image.id,
		description: image.description,
		options: {
			type: image.imageType,
			...imageOptions
		}
	};
}

export function hasImage({ id = '' }: Partial<ImageFieldsFragment>): boolean {
	return Boolean(id && id.indexOf(NO_IMAGE_URL) === -1);
}
