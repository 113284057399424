import { useRef, useContext } from 'react';
import { AnalyticsContext } from '../../contexts/analytics-context/analytics.context';
import { buildGTMDYVariationImpressionEvent } from '../../helpers/analytics/gtm/event-builders';
import { type GTMEvent, type GTMPageData } from '../../helpers/analytics/gtm/gtm-types';
import { doesWindowExist } from '../../helpers/general-helper/general-helper';
import { type AnalyticsPageData, type DYAnalyticsData } from '../../types/analytics.types';
import { useRouteChange } from '../routing/route-change.hook';
import { usePageData } from './page-data.hooks';

type TrackPageDataLayerUpdate = {
	[key: string]: any;
};

type PageViewParams = {
	[key: string]: any;
	pageName: string;
	brand?: string;
	baseCategory?: string;
	businessCategory?: string;
	collection?: string;
};

/**
 * Hook to track page view. Ensures the pageView tracking is only fired once
 */
export function useTrackPageView(pageViewParams: PageViewParams, dataLayerUpdateObj?: TrackPageDataLayerUpdate, isLoading?: boolean) {
	const { pageName, ...otherProps } = pageViewParams;
	/* ref is used to ensure that the page is only tracked once per mount/unmount even if it is re-rendered */
	const pageViewRef = useRef(false);
	const { pageData, isLoading: pageDataIsLoading } = usePageData(pageName);
	const { trackPageView } = useContext(AnalyticsContext);

	useRouteChange(() => {
		// Resets pageRef when history.push or history.replace calls are made (SPA transitions only).
		pageViewRef.current = false;
	});

	if (doesWindowExist() && !pageViewRef.current && !isLoading && pageData && !pageDataIsLoading) {
		const finalPageData: GTMPageData = { ...otherProps, ...pageData };
		trackPageView(finalPageData, dataLayerUpdateObj);
		updateWindowData(finalPageData);
		pageViewRef.current = true;
	}
}

/**
 * Hook to track Dynamic Yield Campaign variation impression events. Ensures it is only fired once
 * after the DY campaign data is loaded. Unlike the useTrackImpressions hook, this hook is not intended to wait until
 * the related element scrolls into view.
 */
export function useTrackDYCampaignImpression(analyticsData: DYAnalyticsData | DYAnalyticsData[] | undefined, isLoading: boolean) {
	/* ref is used to ensure that the page is only tracked once per mount/unmount even if it is re-rendered */
	const campaignViewRef = useRef(false);
	const trackEvent = useTrackEvent();

	if (doesWindowExist() && !campaignViewRef.current && !isLoading && analyticsData) {
		const analyticsDataArray = Array.isArray(analyticsData) ? analyticsData : [analyticsData];
		analyticsDataArray.forEach((item) => trackEvent(buildGTMDYVariationImpressionEvent(item)));
		campaignViewRef.current = true;
	}
}

/**
 * Provides a function to track events via GTM
 * Takes the analytics context into accont.
 */
export function useTrackEvent(): (event: GTMEvent) => void {
	const { trackEvent } = useContext(AnalyticsContext);
	return trackEvent;
}

/**
 * Ensures that the window.analytics object is updated whenever the page-view
 * event is fired
 */
export function updateWindowData(pageData: GTMPageData) {
	window.analytics = { ...window.analytics, ...mapPageData(pageData) };
}

/**
 * Maps GTM page data into the data we need for 3rd party analytics, omits
 * some fields.
 */
export function mapPageData(pageData: GTMPageData): AnalyticsPageData {
	const { page, employee } = pageData;
	return { employee, page };
}
