import { logError } from 'fergy-core-react-logging';
import React, { type FunctionComponent, useEffect, useRef, useState, type CSSProperties, type PropsWithChildren } from 'react';
import { type AnalyticsEvent, AnalyticsHelper } from '../../../helpers/analytics/analytics.helper';
import { focusFirstFocusable } from '../../../helpers/general-helper/general-helper';
import { handleKeys } from '../../../helpers/keyboard/keyboard.helper';
import {
	arrow,
	arrowBottom,
	arrowDark,
	arrowLight,
	arrowTop,
	bottomContainer,
	popover,
	popoverBottom,
	popoverTop,
	topContainer
} from './popover.css';

const DEFAULT_OFFSET = -6;
const MINIMUM_POPOVER_ADJUSTMENT = 3;
const POPOVER_BODY_CLASS = `absolute z-1 flex flex-row justify-around center`;

type Theme = 'light' | 'dark';
type Direction = 'top' | 'bottom';

export type PopoverProps = {
	toggleElement: JSX.Element;
	isVisible: boolean;
	direction?: Direction;
	setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>;
	onToggle?: (event?: React.SyntheticEvent) => void;
	toggleElementClassName?: string;
	parentElementClassName?: string;
	popOverClassName?: string;
	// Analytics data that will sent when the trigger element is clicked.
	analyticsEvent?: AnalyticsEvent;
	theme?: Theme;
	ariaLabel?: string;
	customTextBoxWidth?: CSSProperties['width'];
};

type OffsetState = {
	right: number;
	bottom: number | undefined;
	top: number | undefined;
};

// #region Theme / Direction Handling
const THEME_MAP = {
	light: {
		container: 'bg-theme-white',
		arrow: arrowLight,
		content: 'ba b--theme-grey-light'
	},
	dark: {
		container: 'bg-theme-black theme-white',
		arrow: arrowDark,
		content: ''
	}
};

const getPopOverClasses = (direction: Direction, theme: Theme) => {
	if (direction === 'bottom') {
		return {
			containerClass: `${bottomContainer} dib ${THEME_MAP[theme].container}`,
			contentClass: `${popover} ${popoverBottom} ${POPOVER_BODY_CLASS} ${THEME_MAP[theme].content}`,
			arrowClass: `${arrow} ${arrowBottom} ${THEME_MAP[theme].arrow}`
		};
	} else {
		return {
			containerClass: `${topContainer} ${THEME_MAP[theme].container}`,
			contentClass: `${popover} ${popoverTop} ${POPOVER_BODY_CLASS} ${THEME_MAP[theme].content}`,
			arrowClass: `${arrow} ${arrowTop} ${THEME_MAP[theme].arrow}`
		};
	}
};
// #endregion

// To determine if the toggle width is less that the caret triangle width
const isToggleNarrowerThanArrow = (toggleCoords: DOMRect, arrowCoords: DOMRect | null) =>
	toggleCoords?.width < (arrowCoords?.width || 0) + 20;

export const Popover: FunctionComponent<PropsWithChildren<PopoverProps>> = ({
	children,
	toggleElement,
	isVisible,
	setIsVisible,
	onToggle,
	toggleElementClassName = 'dib',
	parentElementClassName = 'relative dib',
	popOverClassName = '',
	direction = 'top',
	analyticsEvent,
	theme = 'light',
	ariaLabel = 'open',
	customTextBoxWidth
}) => {
	const [arrowOffsetPixels, setArrowOffsetPixels] = useState<OffsetState>({
		right: DEFAULT_OFFSET,
		bottom: undefined,
		top: undefined
	});
	const [popoverOffsetPixels, setPopoverOffsetPixels] = useState<OffsetState>({
		right: DEFAULT_OFFSET,
		bottom: undefined,
		top: undefined
	});
	const toggleRef = useRef<HTMLInputElement>(null);
	const arrowRef = useRef<HTMLInputElement>(null);
	const popoverRef = useRef<HTMLInputElement>(null);

	// Handle blur of popover. Hide popover if focus is moved to an element outside of the popover
	const blurPopoverHandler = () => {
		/*
			Wait for blur to finish and	if new focused element is
			not a child of the popover hide it
			*/
		setTimeout(() => {
			const isActiveElementInPopover = popoverRef.current?.contains(document.activeElement);
			const isActiveToggleElement = toggleRef.current === document.activeElement;

			if (!isActiveToggleElement && !isActiveElementInPopover) {
				toggleVisible();
			}
		}, 0);
	};

	// Prevent focus loss when clicking in the popover
	const mouseDownHandler = (e: React.MouseEvent<HTMLDivElement>) => {
		if (e.currentTarget === popoverRef.current) {
			e.preventDefault();
			focusFirst();
		}
	};

	const focusFirst = () => {
		const didFocus = focusFirstFocusable({ ref: popoverRef });
		if (!didFocus && popoverRef.current) {
			popoverRef.current.focus();
		}
	};

	useEffect(() => {
		focusFirst();
		if (!isVisible) {
			return;
		}

		const toggleCoords = toggleRef.current && toggleRef.current.getBoundingClientRect();
		if (!toggleCoords) {
			return;
		}

		// Center arrow on toggle
		const toggleWidth = toggleCoords.right - toggleCoords.left;
		let arrowOffset = toggleWidth / 2;

		// if the popup would overflow the sides of the window offset by the difference
		const popOverWidth = (popoverRef.current && popoverRef.current.offsetWidth) || 0;
		const popupLeft = toggleCoords.right - popOverWidth;
		const popupRight = toggleCoords.right;
		const modalComponent: HTMLElement | null | undefined = popoverRef.current?.closest('[role="dialog"]');
		const modalLeft = modalComponent?.offsetLeft;
		const isOverLeft = popupLeft < (modalLeft || 0);
		const screenWidth = document.documentElement.clientWidth;
		const isOverRight = popupRight > screenWidth;

		let popoverOffsetRight = DEFAULT_OFFSET;

		if (isOverLeft) {
			popoverOffsetRight = popupLeft - (modalLeft || 0) - MINIMUM_POPOVER_ADJUSTMENT;
		}

		if (isOverRight) {
			popoverOffsetRight = MINIMUM_POPOVER_ADJUSTMENT;
		}

		// #region Overlap
		let arrowOffsetBottom: number | undefined = undefined;
		let popoverOffsetBottom: number | undefined = undefined;
		let arrowOffsetTop: number | undefined = undefined;
		let popoverOffsetTop: number | undefined = undefined;
		const arrowCoords = arrowRef.current && arrowRef.current.getBoundingClientRect();
		const arrowHeight = ((arrowRef.current && arrowRef.current.clientHeight) || 0) / 2;

		let toggleOverlapAmount = 0;
		if (direction === 'top') {
			toggleOverlapAmount = Math.floor((arrowCoords?.bottom || 0) - toggleCoords.top);

			if (isToggleNarrowerThanArrow(toggleCoords, arrowCoords)) {
				// the toggle button has less width then our arrow, move the parent container
				// over to compensate
				arrowOffset = 0;
			} else if (toggleOverlapAmount > 0) {
				arrowOffsetBottom = toggleOverlapAmount;
				popoverOffsetBottom = arrowOffsetBottom + arrowHeight;
			}
		} else {
			toggleOverlapAmount = Math.floor(toggleCoords.bottom - (arrowCoords?.top || 0));

			if (isToggleNarrowerThanArrow(toggleCoords, arrowCoords)) {
				// the toggle button has less width then our arrow, move the parent container
				// over to compensate
				arrowOffset = 3;
			} else if (toggleOverlapAmount > 0) {
				arrowOffsetTop = toggleOverlapAmount;
				popoverOffsetTop = arrowOffsetTop + arrowHeight;
			}
		}
		// #endregion

		setPopoverOffsetPixels({
			right: popoverOffsetRight,
			bottom: popoverOffsetBottom || popoverOffsetPixels.bottom,
			top: popoverOffsetTop || popoverOffsetPixels.top
		});
		setArrowOffsetPixels({
			right: arrowOffset,
			bottom: arrowOffsetBottom || arrowOffsetPixels.bottom,
			top: arrowOffsetTop || arrowOffsetPixels.top
		});
	}, [
		arrowOffsetPixels.bottom,
		arrowOffsetPixels.top,
		direction,
		isVisible,
		popoverOffsetPixels.bottom,
		popoverOffsetPixels.top,
		toggleElement
	]);

	const toggleVisible = (event?: React.SyntheticEvent) => {
		if (event) {
			event.preventDefault();
		}
		if (onToggle) {
			onToggle(event);
		}
		if (!setIsVisible) {
			return;
		}

		if (!isVisible && analyticsEvent) {
			AnalyticsHelper.track(analyticsEvent.data).catch(logError);
		}
		setIsVisible(() => !isVisible);
	};

	const { containerClass, contentClass, arrowClass } = getPopOverClasses(direction, theme);

	return (
		<>
			<div className={parentElementClassName}>
				<div
					className={toggleElementClassName}
					aria-label={ariaLabel}
					ref={toggleRef}
					onClick={toggleVisible}
					onKeyDown={handleKeys(['Enter', ' '], toggleVisible)}
					data-testid="toggle"
					role="button"
					tabIndex={0}>
					{toggleElement}
				</div>
				{isVisible && (
					<div className={`relative z-3 ${containerClass}`}>
						<div ref={arrowRef} style={arrowOffsetPixels} className={`absolute z-3 ${arrowClass} db fade-in`} />
						{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
						<div
							onMouseDown={mouseDownHandler}
							onBlur={blurPopoverHandler}
							tabIndex={-1}
							ref={popoverRef}
							style={{ ...popoverOffsetPixels, width: customTextBoxWidth }}
							className={`${contentClass} outline-0 br1 bg-inherit fade-in ${popOverClassName}`}>
							{children}
						</div>
					</div>
				)}
			</div>
		</>
	);
};
