import { EVENTS } from '../client/constants/general';

declare const window: any;
declare const lightningjs: any;

export type UsabillaCustomVariables = {
	application: string;
	baseCategory: string;
	breadCrumbs: string;
	cartQuantity: number;
	customerID: string;
	isLoggedIn: boolean;
	isPro: boolean;
	lastPageVisit: string;
	manufacturer: string;
	page: string;
	type: string;
};

export const updateUsabillaCustomVariables = () => {
	const customVariables: UsabillaCustomVariables = {
		application: window.dataLayer?.product?.application || 'none',
		baseCategory: window.dataLayer?.product?.baseCategory || 'none',
		breadCrumbs: window.dataLayer?.breadcrumbs || 'none',
		cartQuantity: window.dataLayer?.cartQuantity || 0,
		customerID: window.dataLayer?.customer?.userDBID || 0,
		isLoggedIn: window.dataLayer?.isLoggedIn,
		isPro: window.dataLayer?.customer?.isPro,
		lastPageVisit: window.dataLayer?.lastPageVisit || window.document.referrer || 'none',
		manufacturer: window.dataLayer?.manufacturer || 'none',
		page: window.dataLayer?.page || 'none',
		type: window.dataLayer?.product?.type || 'none'
	};
	window.usabilla_live('data', {
		custom: {
			platform: 'react',
			siteName: 'build',
			storeName: 'build.com',
			...customVariables
		}
	});
};

/**
 * Function calls the embedded script usabilla provides but dynamically adds the buttonId
 *
 * @param buttonId provided by usabilla on the specific button config
 */
export const setupUsabillaButton = (buttonId: string) => {
	if (!window.usabilla_live) {
		// prettier-ignore
		// @ts-expect-error third party init code
		// tslint:disable-next-line
		// eslint-disable-next-line
		window.lightningjs ||(function(c) {function g(b, d) {d && (d += (/\?/.test(d) ? '&' : '?') + 'lv=1');c[b] ||(function() {var i = window,h = document,j = b,g = h.location.protocol,l = 'load',k = 0;(function() {function b() {a.P(l);a.w = 1;c[j]('_load');}c[j] = function() {function m() {m.id = e;return c[j].apply(m, arguments);}var b,e = ++k;b = this && this != i ? this.id || 0 : 0;(a.s = a.s || []).push([e, b, arguments]);m.then = function(b, c, h) {var d = (a.fh[e] = a.fh[e] || []),j = (a.eh[e] = a.eh[e] || []),f = (a.ph[e] = a.ph[e] || []);b && d.push(b);c && j.push(c);h && f.push(h);return m;};return m;};var a = (c[j]._ = {});a.fh = {};a.eh = {};a.ph = {};a.l = d ? d.replace(/^\/\//, (g == 'https:' ? g : 'http:') + '//') : d;a.p = { 0: +new Date() };a.P = function(b) {a.p[b] = new Date() - a.p[0];};a.w && b();i.addEventListener ? i.addEventListener(l, b, !1) : i.attachEvent('on' + l, b);var q = function() {function b() {return ['<head></head><',c,' onload="var d=',n,";d.getElementsByTagName('head')[0].",d,'(d.',g,"('script')).",i,"='",a.l,'\'"></',c,'>'].join('');}var c = 'body',e = h[c];if (!e) return setTimeout(q, 100);a.P(1);var d = 'appendChild',g = 'createElement',i = 'src',k = h[g]('div'),l = k[d](h[g]('div')),f = h[g]('iframe'),n = 'document',p;k.style.display = 'none';e.insertBefore(k, e.firstChild).id = o + '-' + j;f.frameBorder = '0';f.id = o + '-frame-' + j;/MSIE[ ]+6/.test(navigator.userAgent) && (f[i] = 'javascript:false');f.allowTransparency = 'true';l[d](f);try {f.contentWindow[n].open();} catch (s) {(a.domain = h.domain),(p = 'javascript:var d=' + n + ".open();d.domain='" + h.domain + "';"),(f[i] = p + 'void(0);');}try {var r = f.contentWindow[n];r.write(b());r.close();} catch (t) {f[i] = p + 'd.write("' + b().replace(/"/g, String.fromCharCode(92) + '"') + '");d.close();';}a.P(2);};a.l && setTimeout(q, 0);})();})();c[b].lv = '1';return c[b];}var o = 'lightningjs',k = (window[o] = g(o));k.require = g;k.modules = c;})({});
		window.usabilla_live = lightningjs.require('usabilla_live', `//w.usabilla.com/${buttonId}.js`);

		if (!window.dataLayer?.page) {
			document.addEventListener(EVENTS.COMPLETED_PAGE_TRACK, function track() {
				updateUsabillaCustomVariables();
				document.removeEventListener(EVENTS.COMPLETED_PAGE_TRACK, track);
			});
		} else {
			updateUsabillaCustomVariables();
		}
	}
};
/**
 * Resets campaigns for those who have already seen the forms
 * and clears Usabilla data localStorage so that users can submit feedback for multiple search pages
 *
 * @param buttonId provided by usabilla on the specific button config
 */
export const resetCampaigns = (buttonId: string, campaignIds: string[]) => {
	if (!canUseLocalStorage()) {
		return;
	}
	campaignIds.forEach((id) => {
		if (id) {
			window.localStorage.removeItem(`usbl.${buttonId}.c.${id}`);
		}
	});
};

function canUseLocalStorage() {
	let okay = false;
	try {
		if (window && window.localStorage) {
			okay = true;
		}
	} catch {
		// Some browsers (i.e. Firefox) may throw an error on any localStorage access due to user settings blocking it.
	}
	return okay;
}
