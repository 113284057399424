import React, { type FunctionComponent } from 'react';
import { CART_LINK } from '../../../constants/links';
import { useCartHeader } from '../../../hooks/apollo/cart-header/cart-header.hooks';
import { Link } from '../../common-components/link/link.component';
import { type LinkStyleColor } from '../../common-components/link/styled-link.component';
import { CartIcon } from '../../svg/icons.component';
import { cartIconStyles, cartQuantityLeft } from './cart-link.css';

type CartQuantityIndicatorProps = {
	quantity: number;
};

const CartQuantityIndicator: FunctionComponent<CartQuantityIndicatorProps> = ({ quantity }) => {
	const isOver99 = quantity > 99;
	const modifiedFontSize = isOver99 ? `f7` : '';

	return (
		<div className={`h2 tc omniHomeCartIcon`}>
			<div
				className={`relative top-0 omniHomeCartPosition-ns
					omniCartQuantityPosition
				} ${modifiedFontSize} ${cartQuantityLeft} omni-f5-ns omni-f7`}
				data-automation="header-cart-quantity-amount">
				{isOver99 ? '99+' : quantity}
			</div>
			<CartIcon className={`f2 ${cartIconStyles} omniCartIcon`} />
		</div>
	);
};

export type CartLinkProps = {
	isMobile?: boolean;
	isBuild?: boolean;
};

// TODO: EFDC-16856: reduce use of custom css for header
export const CartLink: FunctionComponent<CartLinkProps> = ({ isMobile = false, isBuild = true }) => {
	const { quantity } = useCartHeader();
	let themeColor: LinkStyleColor;

	if (!isBuild) {
		themeColor = 'primary';
	} else {
		themeColor = isMobile ? 'white' : 'grey';
	}

	let linkStyle = '';
	if (isBuild && isMobile) {
		linkStyle = 'dn-ns bg-theme-primary pa2 order-2 h-100';
	}

	return (
		<Link
			url={CART_LINK}
			className={`${linkStyle} omniHomeCartItem flex flex-column justify-center omniMobileCartDisplay`}
			color={themeColor}
			automationHook={`header-cart${isMobile ? '-mobile' : ''}`}
			ariaLabel={`View ${quantity} items currently in cart`}>
			<CartQuantityIndicator quantity={quantity} />
			{!isBuild && isMobile && <span className="f7 lh-title ml2 omniHomeMobileNavCartText">Cart</span>}
		</Link>
	);
};
