import { type ShowroomDetailsQuery, type ShowroomsByLocationQuery } from '../__generated__/graphql-client-types';
import { TrackedEventCase } from '../helpers/analytics/event-types';

export type ShowroomDetails = Extract<ShowroomDetailsQuery['showroomDetails'], { __typename?: 'ShowroomDetails' }>;
export type ProAssociation = ShowroomDetails['proAssociations'][number];
export type ShowroomsByLocationResult = ShowroomsByLocationQuery['showroomsByLocation'];
export type ShowroomLocation = ShowroomsByLocationResult[number];
export type ShowroomAddress = ShowroomLocation['address'];

export const ShowroomBookApptEventTypes = ['cancel', 'finish', 'stage', 'booking-id'] as const;
export type ShowroomBookApptEventType = (typeof ShowroomBookApptEventTypes)[number];
export interface ShowroomBookApptEvent {
	type: ShowroomBookApptEventType;
	detail?: any;
	bookingDetail?: BookingDetail;
}

export type BookingDetail = {
	salesforceId: string;
	appointmentNumber: string;
};

export enum ShowroomBookingSteps {
	'customer-details' = '1',
	'project-details' = '2',
	'work-types' = '3',
	'skill-areas' = '4',
	'appointment-types' = '5',
	'time-slots' = '6',
	'review' = '7',
	'confirmation' = '8'
}

export enum ShowroomBookingStepNames {
	'customer-details' = TrackedEventCase.CUSTOMER_DETAILS,
	'project-details' = TrackedEventCase.PROJECT_DETAILS,
	'work-types' = TrackedEventCase.WORK_TYPES,
	'skill-areas' = TrackedEventCase.SKILL_AREAS,
	'appointment-types' = TrackedEventCase.APPOINTMENT_TYPES,
	'time-slots' = TrackedEventCase.TIME_SLOTS,
	'review' = TrackedEventCase.REVIEW,
	'confirmation' = TrackedEventCase.CONFIRMATION
}
