import { useQuery } from '@apollo/client';
import { logError } from 'fergy-core-react-logging';
import { type CartHeaderQuery, type CartHeaderQueryVariables } from '../../../../client/__generated__/graphql-client-types';
import { doesWindowExist } from '../../../helpers/general-helper/general-helper';
import { CART_HEADER } from '../../../queries/cart/cart-header.queries';
import { usePreviousState } from '../../general/previous-state.hooks';

const defaultCart = {
	quantity: 0,
	id: ''
};

export const useCartHeader = () => {
	const { data, error, loading } = useQuery<CartHeaderQuery, CartHeaderQueryVariables>(CART_HEADER);
	const prevCart = usePreviousState(data?.cart);

	if (error) {
		logError(error.message, error);
	}

	if (doesWindowExist()) {
		if (window.dataLayer?.cartQuantity) {
			window.dataLayer.cartQuantity = data?.cart?.quantity;
		}
	}

	return loading ? prevCart || defaultCart : data?.cart || defaultCart;
};
