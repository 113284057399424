/**
 * Module for defining Google's GTM types. Type documentation can be found here:
 * https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data
 */

import type { CustomerTypeEnum, Maybe } from '../../../__generated__/graphql-client-types';
import type { CurrencyCode } from '../../../constants/currency';
import { type BookingDetail } from '../../../types/showroom.types';
import type { FindingMethodSubtype, FindingMethodType, TrackedEvent, TrackedEventCase } from '../event-types';

// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#product-data
export type GTMProductBase = {
	// The product ID or SKU (e.g. P67890).
	id: string;
	// The name of the product (e.g. Android T-Shirt).
	name: string;
	// The brand associated with the product (e.g. Google).
	brand?: string;
	/* The category (aka baseCategory) to which the product belongs (ex: Apparel). Use / as a
	delimiter for up to 5 levels of hierarchy (ex: Apparel/Men/T-Shirts). */
	category?: string;
	// The variant of the product (e.g. Black).
	variant?: Maybe<string>;
	// The price of a product (e.g. 29.20).
	price?: number;
	// The quantity of a product (e.g. 2).
	quantity?: number;
	// The coupon code associated with a product (e.g. SUMMER_SALE13).
	coupon?: string;
	// The product's position in a list or collection (e.g. 2).
	position?: number;
};

/**
 * Enum to apply semantic labels to our custom GTM product dimensions.
 * Keep list sorted by `dimension` value for easier comparison
 */
export enum GTMCustomProductDimensions {
	BUSINESS_CATEGORY = 'dimension2',
	COLLECTION = 'dimension3',
	PRODUCT_TYPE = 'dimension13',
	PRODUCT_APPLICATION = 'dimension14',
	IS_OUT_OF_STOCK = 'dimension48',
	HAS_IMAGE = 'dimension50',
	ADD_TO_CART_METHOD = 'dimension53',
	PRODUCT_ID = 'dimension54',
	IS_ON_SALE = 'dimension58',
	HAS_RECOMMENDED_OPTIONS = 'dimension83',
	FULFILLMENT_METHOD = 'dimension94',
	FULFILLMENT_SPEED = 'dimension97'
}

/**
 * All the GTM product fields which aren't part of Google's spec
 */
export type GTMProductCustom = {
	// Business category
	[GTMCustomProductDimensions.BUSINESS_CATEGORY]?: string;
	// Collection
	[GTMCustomProductDimensions.COLLECTION]?: string;
	// Flag if product family is on sale
	[GTMCustomProductDimensions.IS_ON_SALE]?: boolean;
	// Flag if finish/variant is out of stock
	[GTMCustomProductDimensions.IS_OUT_OF_STOCK]: boolean;
	// Flag if finish/variant has an image
	// TODO: Make req'd after necessary schema changes
	[GTMCustomProductDimensions.HAS_IMAGE]?: boolean;
	// Product Application
	[GTMCustomProductDimensions.PRODUCT_APPLICATION]?: string;
	/**
	 * Also referred to as Model Number
	 *
	 * @example K-394
	 */

	[GTMCustomProductDimensions.PRODUCT_ID]: string;
	// Product Type
	[GTMCustomProductDimensions.PRODUCT_TYPE]?: string;
	// Product Has Recommended Options
	[GTMCustomProductDimensions.HAS_RECOMMENDED_OPTIONS]?: boolean;
	[GTMCustomProductDimensions.FULFILLMENT_METHOD]?: FULFILLMENT_METHOD;
	[GTMCustomProductDimensions.FULFILLMENT_SPEED]?: FULFILLMENT_SPEED;
};

export type GTMProduct = GTMProductBase & GTMProductCustom;

export type GTMSearchProductClick = GTMProductBase &
	Omit<GTMProductCustom, GTMCustomProductDimensions.IS_OUT_OF_STOCK | GTMCustomProductDimensions.IS_ON_SALE>;

// https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#impression-data
export type GTMImpressionBase = {
	// The product ID or SKU (e.g. P67890).
	id: string;
	// The name of the product (e.g. Android T-Shirt).
	name: string;
	// The list or collection to which the product belongs (e.g. Search Results)
	list?: string;
	// The brand associated with the product (e.g. Google).
	brand?: string;
	/* The category to which the product belongs (ex: Apparel). Use / as a
	delimiter for up to 5 levels of hierarchy (ex: Apparel/Men/T-Shirts). */
	category?: string;
	// The variant of the product (e.g. Black).
	variant?: string;
	// The product's position in a list or collection (e.g. 2).
	position?: number;
	// The price of a product (e.g. 29.20).
	price?: number;
};

export type GTMImpression = GTMImpressionBase & GTMProductCustom;

/**
 *	A generic type to represent a GTM event object
 */
type GTMGenericEvent = {
	/* This is arbitrary but should be expressive and limited to a predefined
	enum we maintain ourselves and coordinate with analytics team. */
	event: TrackedEvent;
};

export type GTMEventData = {
	[variable: string]: any;
};

export type GTMCustomEvent = GTMGenericEvent & GTMEventData;

/**
 * Not explicitly documented for some reason, but can be seen here:
 *
 * https://developers.google.com/tag-manager/enhanced-ecommerce
 *
 * This is the generic schema which wraps each of the event types.
 */
export type GTMECommerceEventWrapper<EcommerceType = AllGTMEvents> = GTMGenericEvent & {
	/* The value of 'ecommerce' can be one of several event "types" for GTM.
	Weirdly, they aren't listed in an explicit type spec, but can be inferred
	from the code examples at the above enhanced ecommerce link. */
	ecommerce?: EcommerceType;
	/* BUILD-CUSTOM. The 'type' distinguishes similar events from one another,
	such as add-to-cart from two different pages or buttons. */
	type?: TrackedEventCase;
	/* BUILD-CUSTOM. Used to track when a search was done from a previous search
	page. i.e. Search for 'blue toilets', then search for 'black toilets'. The search-term
	will be populated with 'blue toilets on the second search. */
	'search-term'?: string;
	/* Optional callback for when the event tracking finishes */
	eventCallback?: () => void;
	// Optional timeout to manually fire eventCallback if tags stall
	eventTimeout?: number;
};

export type GTMEventWrapper<T = AllGTMEvents> = GTMECommerceEventWrapper<T> | GTMCustomEvent;
/* friendlier name */
export type GTMEvent<T = AllGTMEvents> = GTMEventWrapper<T>;

/* summary of a cart item */
export type GTMCartSummaryItem = {
	uniqueId: number;
	productQuantity: number;
	unitPrice: number;
};

/* type to support sending a summary of cart data with cart change events */
export type GTMCartSummary = {
	items: GTMCartSummaryItem[];
};

/**
 * BEGIN ACTION TYPE SPECS
 *
 * Each GTM 'enhanced ecommerce' action has a type specification. The link below
 * shows a relationship between the action type and the name of the key used in
 * the datalayer object. Ex: add to cart => 'add'
 *  https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#action-types
 */

// Provided in the event wrapper to track add to cart
export type GTMAddToCartEvent = {
	currencyCode: CurrencyCode;
	add: {
		products: GTMProduct[];
	};
	summary: GTMCartSummary;
};

// Provided in the event wrapper to track add to cart
export type GTMRemoveFromCartEvent = {
	remove: {
		products: GTMProduct[];
	};
};

// Event wrapper to track search results view
export type GTMViewSearchResultsEvent = {
	currencyCode?: CurrencyCode;
	impressions: GTMImpression[];
};
// Event wrapper to track views of products
export type GTMViewProductEvent = {
	detail: {
		products: GTMProduct[];
	};
};

export type GTMSearchProductClickEvent = {
	click: {
		actionField: {
			list: string;
		};
		products: [GTMSearchProductClick];
	};
};

// All GTM Ecommerce event types
export type AllGTMEvents =
	| GTMAddToCartEvent
	| GTMRemoveFromCartEvent
	| GTMViewSearchResultsEvent
	| GTMViewProductEvent
	| GTMCheckoutStepEvent
	| GTMSearchProductClickEvent;

export type GTMCouponSubmission = {
	event: TrackedEvent;
	coupon: string;
	isLoggedIn: boolean;
	isPro: boolean;
	cartTotal: number;
	error: string;
	status: string;
};

export type GTMCheckoutStepEvent = {
	checkout: {
		actionField: {
			step: number;
			option?: string;
		};
		products: GTMProduct[];
	};
};

export type GTMCheckoutStep = GTMEventWrapper<GTMCheckoutStepEvent> & {
	page?: string;
};

export type GTMClickTracking = GTMEventWrapper & {
	linkName: string;
};

export type GTMProjectInteractionTracking = GTMEventWrapper & {
	component: string;
	interaction: string;
};

export type GTMValidationErrorTracking = GTMEventWrapper & {
	event: string;
	message: string;
};

export type GTMCustomerCheckoutTypeTracking = GTMEventWrapper & {
	event: string;
	customerCheckoutType: string;
};

export type GTMPaymentMethodClickedTracking = GTMEventWrapper & {
	event: string;
	type: 'amazon' | 'paypal';
};

type DataLayerCustomer = {
	isPro: boolean;
	userDBID: string;
};
type DataLayerEmployee = {
	isAuthenticated: boolean;
};

// The basic page level data that GTM needs for all pages.
export type GTMPageData = {
	customer: DataLayerCustomer;
	employee: DataLayerEmployee;
	page: string;
	brand?: string;
	baseCategory?: string;
	businessCategory?: string;
	collection?: string;
};

export type ProjectTrackingInteractions =
	| 'leave_project'
	| 'delete_project'
	| 'copy_project'
	| 'archive_project'
	| 'accept_project_invite'
	| 'reject_project_invite'
	| 'copy_example_project';

export type GTMFindingMethodEvent = GTMCustomEvent & {
	event: TrackedEvent.FINDING_METHODS;
	findingMethod: FindingMethodType;
	findingSubType: FindingMethodSubtype;
};

export type GTMOrderReturnSearchHistory = {
	event: TrackedEvent;
	searchType: string | null;
	searchTerm: string | null;
	sortOrder: string;
	status: string;
	numberOfResults: number;
};

export type GTMCustomerLoginEvent = GTMCustomEvent & {
	event: TrackedEvent.CUSTOMER_LOGIN;
	customerType: CustomerTypeEnum;
	userDBID: number;
	isNewAccount: boolean;
};

export enum FULFILLMENT_METHOD {
	PARCEL = 'parcel',
	LTL = 'LTL'
}

export enum FULFILLMENT_SPEED {
	STANDARD = 'Standard',
	WHITE_GLOVE = 'White Glove',
	TWO_DAY = 'Two Day',
	ONE_DAY = 'One Day'
}

export enum PAGE_NAME {
	CART = 'cart:cart',
	CHECKOUT = 'checkout:onepage'
}

/**
 * This event matches up with what the DY client script sends for Client-side campaign events
 *
 * @see https://support.dynamicyield.com/hc/en-us/articles/11809464222621-Google-Analytics-Integration-Legacy-
 */
export type GTMDYVariationImpressionEvent = {
	event: TrackedEvent.DY_EVENT;
	eventAction: string; // example: 'My Campaign'
	eventCategory: 'DY Smart Object';
	eventLabel: string; // example: 'Experience 1 (Variation 1)'
};

export type GTMShowroomBookingEvent = {
	event: TrackedEvent.SHOWROOM_BOOKING_FLOW;
	step: string;
	step_name: string;
	detail?: string;
	bookingDetail?: BookingDetail;
};
